<div fpfLayout="column">

    <mat-dialog-content>
        <div fpfLayout="column" fpfLayoutGap="4">
            <div class="shield-bit" fpfFlex fpfLayoutAlign="center start" fpfLayout="row" fpfLayoutGap="4">
                <img src="../../../../../assets/images/shield-bit.svg" width="240px" alt="">
                <h2>{{'lgpd-tittle' | translate}}</h2>
            </div>
            <p>
                {{(('strategic-lgpd-message 1'| translate).replace('{name}',this.v.name )).replace('{email}',this.v.email)}}
            </p>
            <p>
                <b>{{'strategic-lgpd-topic 1' | translate}}</b>{{'strategic-lgpd-message 2'| translate}}
            </p>
            <p>
                <b>{{'strategic-lgpd-topic 2' | translate}}</b>{{'strategic-lgpd-message 3'| translate}}
            </p>
            <p>
                <b>{{'strategic-lgpd-topic 3' | translate}}</b>{{'strategic-lgpd-message 4'| translate}}
            </p>
            <p>
                <b>{{'strategic-lgpd-topic 4' | translate}}</b>{{'strategic-lgpd-message 5'| translate}}
            </p>
            <p>
                <b>{{'strategic-lgpd-topic 5' | translate}}</b>{{'strategic-lgpd-message 6'| translate}}
            </p>
            <p>
                <b>{{'strategic-lgpd-topic 6' | translate}}</b>{{'strategic-lgpd-message 7'| translate}}
            </p>
            <p>
                <b>{{'strategic-lgpd-topic 7' | translate}}</b>{{'strategic-lgpd-message 8'| translate}}
            </p>
            <p>
                <b>{{'strategic-lgpd-topic 8' | translate}}</b>{{'strategic-lgpd-message 9'| translate}}
            </p>
            <p>
                <b>{{'strategic-lgpd-topic 9' | translate}}</b>{{'strategic-lgpd-message 10'| translate}}
            </p>
        </div>

    </mat-dialog-content>


</div>
