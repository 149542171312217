import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FilterConfiguration, FilterResult, SelectedFilter} from "../filter-configuration";
import {FormBuilder, FormGroup} from "@angular/forms";
import {CustomValidators} from "../../../utilities/validator/custom-validators";


@Component({
    selector: "app-filter-input-dialog",
    templateUrl: "./filter-input-dialog.component.html",
    styleUrls: ["./filter-input-dialog.component.scss"]
})
export class FilterInputDialogComponent implements OnInit {

    public formGroup: FormGroup;

    constructor(@Inject(MAT_DIALOG_DATA) public data: FilterConfiguration,
                public dialogRef: MatDialogRef<any>,
                public formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.createFormGroup();
    }

    private createFormGroup(): void {
        this.formGroup = this.formBuilder.group({
            value: [null, CustomValidators.required]
        });

    }

    public ok(): void {
        const filterResult = new FilterResult();
        filterResult.value = this.formGroup.controls.value.value;

        const selectedFilter = new SelectedFilter();
        Object.assign(selectedFilter, this.data);
        selectedFilter.result = filterResult;

        this.dialogRef.close(selectedFilter);
    }

}
