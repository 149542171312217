<div fpfLayout="column" fpfLayoutAlign="stretch start" class="min-h-full">
    <mat-toolbar fpfLayout="row" fpfLayoutAlign="center between" class="mat-dialog-header">
        <span>{{ 'history' | translate }}</span>
        <button mat-icon-button [mat-dialog-close]="false" tabindex="-1">
            <mat-icon>close</mat-icon>
        </button>
    </mat-toolbar>

    <mat-dialog-content>

        <div fpfLayout="column" class="pd-top-24">

            <mat-accordion fpfLayout="column">
                @for(element of historyList; track historyList) {
                    <div fpfLayout="column" class="item-history">
                        <div class="icon">
                            <mat-icon>history</mat-icon>
                        </div>

                        @if(element['changes'].length > 0) {
                            <mat-expansion-panel class="expansion-panel">

                                <mat-expansion-panel-header class="panel-header">
                                    <mat-panel-title>
                                        {{ element['history_user']?.name }}
                                    </mat-panel-title>
                                    <mat-panel-description>
                                        {{ element['history_date'] | localDate }}
                                    </mat-panel-description>
                                    <mat-panel-description>
                                        @if(element['history_type'] === '+') {
                                            <div>
                                                <span class="badge badge-success">{{ 'added' | translate }}</span>
                                            </div>
                                        } @else if (element['history_type'] === '~') {
                                            <div>
                                                <span class="badge badge-info">{{ 'edited' | translate }}</span>
                                            </div>
                                        }
                                    </mat-panel-description>
                                </mat-expansion-panel-header>

                                <mat-list>
                                    @for(change of element['changes']; track change) {
                                        <mat-list-item class="grid">
                                            @if(element['history_type'] === '+') {
                                                <div fpfLayout fpfFlex class="grid">
                                                    <strong
                                                        class="col-4">{{ normalize(change['field'])| translate | tooLong }}:</strong>
                                                    <span
                                                        class="col-8">{{ getValue(change['new']) | removeHtmlTags | emptyValue }}</span>
                                                </div>
                                            } @else if (element['history_type'] === '~') {
                                                <div fpfLayout fpfFlex fpfLayoutGap="2" class="gap-3">
                                                    <span>{{ normalize(change['field'])| translate }}: </span>
                                                    <strong> {{ getValue(change['old']) | emptyValue | removeHtmlTags}} </strong>
                                                    <span>{{ 'modified' | translate }} {{ 'to' | translate }}: </span>
                                                    <strong> {{ getValue(change['new']) | emptyValue | removeHtmlTags}} </strong>
                                                </div>
                                            }
                                        </mat-list-item>
                                    }
                                </mat-list>
                            </mat-expansion-panel>
                        }

                    </div>
                }

                <mat-expansion-panel [expanded]="true" *ngIf="paginator.length === 0">
                    {{ 'no-records-found' | translate }}
                </mat-expansion-panel>

            </mat-accordion>

            <div class="paginator">
                <mat-paginator #paginator
                               [showFirstLastButtons]="true"
                               [length]="paginator.length"
                               [pageIndex]="paginator.pageIndex"
                               [pageSize]="paginator.pageSize"
                               [pageSizeOptions]="paginator.pageSizeOptions"
                               (page)="search()">
                </mat-paginator>
            </div>

        </div>

    </mat-dialog-content>
</div>

