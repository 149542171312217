import {ModelBase} from "../model-base";
import {Skill} from "../levelup/skill";
import {Vacancy} from "./vacancy";


export class VacancySkill extends ModelBase {
    vacancy: Vacancy | string;
    skill: Skill | string;
    required: boolean;
}
