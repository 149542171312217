import {Component, Inject, InjectionToken, Injector, OnDestroy, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FilterConfiguration, FilterResult, SelectedFilter} from "../filter-configuration";
import {FormBuilder, FormGroup} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {Subject} from "rxjs";
import {Choice} from "../../../dto/choice";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {BaseService} from "../../../services/base.service";
import {ModelBase} from "../../../models/model-base";
import {AESEncryptDecryptService} from "../../../services/crypto.service";
import {CustomValidators} from "../../../utilities/validator/custom-validators";


@Component({
    selector: "app-filter-choice-multiple-dialog",
    templateUrl: "./filter-choice-multiple-dialog.component.html",
    styleUrls: ["./filter-choice-multiple-dialog.component.scss"]
})
export class FilterChoiceMultipleDialogComponent implements OnInit, OnDestroy {
    public unsubscribe = new Subject();
    public formGroup: FormGroup;
    public service: BaseService<ModelBase>;
    public choiceList: Choice[] = [];
    public filterResults: FilterResult[] = [];
    readonly addOnBlur = true;
    readonly separatorKeysCodes = [ENTER, COMMA] as const;

    constructor(public injector: Injector,
                public http: HttpClient,
                public CryptService: AESEncryptDecryptService,
                @Inject(MAT_DIALOG_DATA) public data: FilterConfiguration,
                public dialogRef: MatDialogRef<any>,
                public formBuilder: FormBuilder) {
        this.service = this.createService(data.choiceConfiguration.model, data.choiceConfiguration.urlEndpoint);
    }


    ngOnInit() {
        this.createFormGroup();
    }

    public createService<K>(model: new () => K, path: string): BaseService<K> {
        const TOKEN = new InjectionToken<BaseService<K>>("service_" + path, {
            providedIn: "root", factory: () => new BaseService<K>(this.http, path),
        });
        return this.injector.get(TOKEN);
    }


    public createFormGroup(): void {
        this.formGroup = this.formBuilder.group({
            value: [null, CustomValidators.required]
        });
    }

    public ok(): void {
        const selectedFilter = new SelectedFilter();
        Object.assign(selectedFilter, this.data);
        selectedFilter.result = this.filterResults;
        this.dialogRef.close(selectedFilter);
    }

    public remove(_filterResult: FilterResult): void {
        const index = this.filterResults.findIndex((filterResult: FilterResult) => filterResult.value === _filterResult.value);
        this.filterResults.splice(index, 1);
    }

    public add(): void {
        const value = this.formGroup.controls.value.value;
        const index = this.filterResults.findIndex((filterResult: FilterResult) => filterResult.value === value);
        if (index >= 0) {
            return;
        }

        const indexChoice = this.choiceList.findIndex(item => item.value == value);
        const display = this.choiceList[indexChoice].display_name;

        const filterResult = new FilterResult();
        filterResult.value = value;
        filterResult.display = display;

        this.filterResults.push(filterResult);
        this.formGroup.reset();
    }

    public getChoices(): void {
        this.service.getChoices(this.data.choiceConfiguration.choiceField)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(response => this.choiceList = response);
    }


    public ngOnDestroy() {
        this.unsubscribe.next({});
        this.unsubscribe.complete();
    }


}
