<mat-toolbar class="mat-dialog-header">
    <span class="flex-auto">{{ data?.title ?? "delete-confirm" | translate }}</span>
    <button mat-icon-button (click)="close(false)">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content fpfFlex fpfFlexLayout="column">
    <div>{{ data?.message ?? 'delete-confirm-data' | translate }}</div>
    <div class="mt-2"><strong>{{ data?.description | translate }}</strong></div>

    <div fpfFlex fpfFlexLayout="row" fpfLayoutAlign="between center" class="gap-3 mt-2">
        <button mat-stroked-button color="warn" type="button" id="cancel" (click)="close(false)">
            {{ data.cancel ?? 'cancel' | translate }}
        </button>

        <button mat-flat-button color="accent" id="save" type="submit" (click)="close(true)">
            {{ data?.confirmationButton ?? "yes-delete" | translate }}
        </button>
    </div>
</mat-dialog-content>
