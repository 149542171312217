<mat-toolbar class="mat-dialog-header">
    <span class="flex-1">{{'filter' | translate}} {{'by' | translate}} {{data.label | translate}}</span>
    <button mat-icon-button [mat-dialog-close]="false">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>


<mat-dialog-content class="mat-typography">
    <form [formGroup]="formGroup" class="flex flex-column gap-3">
        <div class="flex align-items-start gap-2">
            <mat-form-field class="flex-grow-1  ">
                <mat-label>{{data.label | translate}}</mat-label>
                <input [type]="data.fieldType" formControlName="value" matInput autoFocus>

                <mat-error validator></mat-error>
            </mat-form-field>
            <button mat-raised-button color="accent" [disabled]="!formGroup.valid" (click)="add()">
                {{ "add" | translate }}
            </button>
        </div>

        <div class="flex-column gap-1 actions-chip-content">
            <strong>{{'you-will-be-filtered-by' | translate}}:</strong>
            <mat-chip-grid #chipList aria-label="Fruit selection">
                @for(result of filterResults; track filterResults) {
                    <mat-chip-row (removed)="remove(result)">
                        {{ result.value }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                }
                <input [matChipInputFor]="chipList"
                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                       [matChipInputAddOnBlur]="addOnBlur"
                       [readonly]="true">
            </mat-chip-grid>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end" class="actions-dialog">
    <button mat-raised-button color="accent" (click)="ok()" [disabled]="!filterResults.length">
        {{'confirm' | translate}}
    </button>
</mat-dialog-actions>
