import {Component, Injector, Input, OnInit} from "@angular/core";
import {BaseComponentDirective, BaseOptions} from "../../base-component.directive";
import {Vacancy} from "../../../models/rh/vacancy";
import {URLS} from "../../../app/app.urls";
import {MainService} from "../main/main.service";
import {takeUntil} from "rxjs/operators";
import {Choice} from "../../../dto/choice";

const BASE_OPTIONS: BaseOptions = {
    pk: "id",
    endpoint: URLS.VACANCY,
    searchOnInit: true,
};

@Component({
    selector: "app-external-vacancy",
    templateUrl: "./external-vacancy.component.html",
    styleUrls: ["./external-vacancy.component.scss"]
})
export class ExternalVacancyComponent extends BaseComponentDirective<Vacancy> implements OnInit {

    @Input() Vacancy = new Vacancy();
    public object: Vacancy = new Vacancy();
    public vacancyList: Vacancy[] = [];
    public jobModality: Choice[] = [];


    constructor(public mainService: MainService,
                public injector: Injector,
    ) {
        super(injector, BASE_OPTIONS);
    }

    ngOnInit() {
        this.search();
        this.getJobModality();
    }

    createFormGroup(): void {
    }

    public search(): void {
        this.service.clearParameter();
        this.service.addParameter("active", true);
        this.service.addParameter("expand", "role_type,function");
        this.service.addParameter("status_vacancy", "SB");
        this.service.getFromListRoute("list_vacancies")
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(response => {
                // @ts-ignore
                this.vacancyList = response;
            });
    }

    public getJobModality(): void {
        this.service.clearParameter();
        this.service.getChoices("job_modality")
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(response => {
                this.jobModality = response;
            });
    }
}
