export class LayoutService {

    private containerFlex(columnOrRow?: string, justifyContent?: string, alignItems?: string) {
        const nameClass = ["flex", `flex-${columnOrRow}`];
        if (justifyContent) nameClass.push(`justify-content-${justifyContent}`);
        if (alignItems) nameClass.push(`align-items-${alignItems}`);
        return nameClass;
    }

    public flexLayout(value = "row") {
        return this.containerFlex(...value.split("-"));
    }
}
