<div [style.background-color]="backgroundColor" class="bg-modules">
    <button mat-icon-button class="close-icon" (click)="dialogRef.close()">
        <mat-icon>close</mat-icon>
    </button>
    <div class="centered-container">
        @for (m of modules; track m) {
            <button
                (click)="changeModule(m)"
                class="card"
                [ngClass]="{
                'selected': data.selectedModule?.description === m.description}">
                <img src="{{modulesIcon[m?.description]}}" height="120px" alt="">
                <span
                    [ngClass]="{'selected-text': data.selectedModule?.description === m?.description}">{{ m?.description | translate }}</span>
            </button>
        }
    </div>

</div>
