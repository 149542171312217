import * as moment from "moment";
import {SafeUrl} from "@angular/platform-browser";

export class Utils {

    public static getNickname(name: string): string {
        let str = "";

        if (!name) {
            return str;
        }
        const split = name.split(" ");
        if (split.length > 0) {
            str = split[0].charAt(0);
            if (split.length > 1) {
                str += split[1].charAt(0);
            }
        }
        return str.toUpperCase();
    }

    public static convertBase64ToImage(base64: string | Blob): string {
        return base64 ? "" + base64 : null;
    }

    public static convertImageToBlob(data: SafeUrl, type: string) {
        // convert base64 to raw binary data held in a string
        const byteString = atob(data.toString().split(",")[1]);

        // write the bytes of the string to an ArrayBuffer
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        return new Blob([ab], {type: type});
    }

    public static downloadFileFromBlob(file: Blob, filename: string): any {
        const fileUrl = (window.URL || window["webkitURL"]).createObjectURL(file);
        const anchor = document.createElement("a");
        anchor.download = filename;
        anchor.href = fileUrl;
        anchor.dispatchEvent(
            new MouseEvent("click", {bubbles: true, cancelable: true, view: window})
        );
        anchor.remove();
    }

    public static normalize(str: string): string {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    public static nowStr(format: string = "YYYY-MM-DD HH:mm:ssZ"): string {
        return moment().format(format);
    }

    public static momentStr(date, format: string = "YYYY-MM-DD HH:mm:ssZ"): string {
        return moment(date).format(format);
    }

    public static isNull(value): boolean {
        return value === "" || value === undefined || value === null;
    }

    public static isDate(value) {
        let dateFormat;
        if (toString.call(value) === "[object Date]") {
            return true;
        }
        if (typeof value.replace === "function") {
            value.replace(/^\s+|\s+$/gm, "");
        }
        dateFormat = /(^\d{1,4}[\.|\\/|-]\d{1,2}[\.|\\/|-]\d{1,4}).+?$/;
        return dateFormat.test(value);
    }

    public static deepCopy<T>(obj: T): T {
        if (obj === null || typeof obj !== "object") {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => Utils.deepCopy(item)) as any;
        }

        const copiedObj = {} as T;
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                copiedObj[key] = Utils.deepCopy(obj[key]);
            }
        }
        return copiedObj;
    }

}

String.prototype.padStart = function padStart(targetLength, padString) {
    targetLength = targetLength >> 0;
    padString = String(typeof padString !== "undefined" ? padString : " ");
    if (this.length >= targetLength) {
        return String(this);
    } else {
        targetLength = targetLength - this.length;
        if (targetLength > padString.length) {
            padString += padString.repeat(targetLength / padString.length);
        }
        return padString.slice(0, targetLength) + String(this);
    }
};

String.prototype.padEnd = function padEnd(targetLength, padString) {
    targetLength = targetLength >> 0;
    padString = String((typeof padString !== "undefined" ? padString : " "));
    if (this.length > targetLength) {
        return String(this);
    } else {
        targetLength = targetLength - this.length;
        if (targetLength > padString.length) {
            padString += padString.repeat(targetLength / padString.length);
        }
        return String(this) + padString.slice(0, targetLength);
    }
};
