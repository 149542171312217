import {Component, Injector, OnInit} from "@angular/core";
import {BaseComponentDirective, BaseOptions} from "../../base-component.directive";
import {User} from "../../../models/account/user";
import {URLS} from "../../../app/app.urls";
import {SafeUrl} from "@angular/platform-browser";
import {Utils} from "../../../utilities/utils";
import {ProfileBadgeItemComponent} from "./profile-badge-item/profile-badge-item.component";

const BASE_OPTIONS: BaseOptions = {
    pk: "id",
    endpoint: URLS.USER,
    retrieveOnInit: true,
    retrieveRoute: "custom_retrieve"
};

@Component({
    selector: "app-profile-badge",
    templateUrl: "./profile-badge.component.html",
    styleUrls: ["./profile-badge.component.scss"]
})
export class ProfileBadgeComponent extends BaseComponentDirective<User> implements OnInit {

    public avatar: SafeUrl;

    public object: User = new User();

    public hover = true;

    constructor(injector: Injector
    ) {
        super(injector, BASE_OPTIONS);
    }

    createFormGroup() {
    }

    ngOnInit() {
        super.ngOnInit(() => {
            this.loadImage();
            this.OpenDialog();
        });
    }

    public loadImage() {
        if (this.object.avatar_minio) {
            this.avatar = Utils.convertBase64ToImage(this.object.avatar_minio);
        }
    }

    public OpenDialog(): void {
        const dialogRef = this.dialog.open(ProfileBadgeItemComponent, {
            width: "310px",
            height: "95px",
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(() => {
            this.hover = !this.hover;
        });
    }

    protected readonly Object = Object;
}
