export enum ModuleType {
    LEVELUP = "levelup",
    SETTINGS = "settings",
    PURCHASES = "purchases",
    RH = "rh",
    LABS = "labs",
    BASIC_REGISTRATION = "basic-registration",
    PATRIMONY = "patrimony"
}


export enum ThemeColor {
    LEVELUP = "#4CAE50",
    SETTINGS = "#5D7786",
    PURCHASES = "#33691E",
    RH = "#2962FF",
    LABS = "#6237B0",
    BASIC_REGISTRATION = "#FF6D00",
    PATRIMONY = "#009083"
}


export class Module {
    url: string;
    id: number;
    code: string;
    description: string;
    theme: string;
    default_route: string;
    order: number;

    // extra fields
    granted: boolean;
}
