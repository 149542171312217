<div [fpfFill]="true" class="login" fpfLayout="column" fpfLayoutAlign="center center" fpfLayoutGap="4">

    <form [formGroup]="formGroup">
        <mat-card fpfFlex="400px">
            <div mat-card-image fpfLayoutAlign="center center" fpfLayout="column">
                <div>
                    <img src="../../../../assets/images/bit-initial.svg" height="85px" style="margin-bottom: 10px"/>
                </div>
                <div>
                    <img src="assets/images/logo-fpf.svg" height="28px" class="logo-fpf" alt="FPF-Tech">
                </div>
            </div>

            <mat-card-content fpfLayout fpfLayoutAlign="center evenly" fpfLayoutGap="2">
                <div fpfLayout="column" fpfFlex="90" fpfLayoutGap="2">
                    <mat-form-field>
                        <mat-label>{{ 'full-name'| translate }}</mat-label>
                        <input matInput type="text" maxlength="64" formControlName="name" required autoFocus
                               autocomplete="off">
                        <mat-error validator>{{ 'the-field-is-mandatory' | translate }}</mat-error>
                    </mat-form-field>

                    <mat-form-field fpfLayout fpfFlex>
                        <mat-label>{{ "email" | translate }}</mat-label>
                        <input matInput type="text" maxlength="64" formControlName="email" required>
                        <mat-error validator>{{ 'the-field-is-mandatory' | translate }}</mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{ 'username'| translate }}</mat-label>
                        <input matInput type="text" maxlength="64" formControlName="username" required>
                        <mat-error validator>{{ 'the-field-is-mandatory' | translate }}</mat-error>
                    </mat-form-field>

                    <mat-form-field fpfLayout fpfFlex>
                        <mat-label>{{ 'password' | translate }}</mat-label>
                        <input matInput [type]="hide ? 'password' :'text'"
                               formControlName="password"
                               minlength="8"
                               maxlength="64"
                               required>
                        <mat-error validator>{{ 'the-field-is-mandatory' | translate }}</mat-error>

                        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hide">
                            <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                        </button>
                    </mat-form-field>

                    <mat-form-field fpfLayout fpfFlex>
                        <mat-label>{{ "confirm-password" | translate }}</mat-label>
                        <input matInput [type]="hide ? 'password' :'text'"
                               formControlName="conf_password"
                               minlength="8"
                               maxlength="64"
                               required>
                        <mat-error validator>{{ 'the-field-is-mandatory' | translate }}</mat-error>

                        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hide">
                            <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                        </button>
                    </mat-form-field>

                    <div fpfLayout fpfLayoutAlign="row" fpfLayoutGap="1">
                        <mat-checkbox formControlName="checked_term"></mat-checkbox>
                        @if (formGroup.valid){
                            <div fpfLayout fpfLayoutAlign="center"> {{ 'agree-with' | translate }}<a
                                (click)="openLgpdTermDialog()">{{ 'show-term'| translate }}</a></div>
                        } @else {
                            <div fpfLayout fpfLayoutAlign="center"> {{ 'agree-with' | translate }}
                                {{ 'show-term'| translate }}</div>
                        }

                    </div>

                </div>
            </mat-card-content>

            <mat-card-actions fpfLayout fpfLayoutAlign="center evenly" fpfLayoutGap="4">
                <div>
                    <button fpfLayout fpfFlex="50%" type="submit" mat-button
                            [disabled]="!formGroup.valid || !this.v.checked_term"
                            (click)="saveOrUpdate()">
                        {{ 'send' | translate }}
                    </button>
                </div>
            </mat-card-actions>

        </mat-card>
    </form>
</div>
