import {
    AfterViewInit,
    Component,
    InjectionToken,
    Injector,
    Input,
    OnDestroy,
    Output,
    ViewEncapsulation
} from "@angular/core";
import {
    BaseFilter,
    DateRange,
    FieldType,
    FilterConfiguration,
    FilterResult,
    FilterType,
    NumberRange,
    SelectedFilter,
} from "./filter-configuration";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FilterInputDialogComponent} from "./filter-input-dialog/filter-input-dialog.component";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {FilterInputRangeDialogComponent} from "./filter-input-range-dialog/filter-input-range-dialog.component";
import {FilterDatePickerDialogComponent} from "./filter-datepicker-dialog/filter-date-picker-dialog.component";
import {
    FilterDatePickerRangeDialogComponent,
} from "./filter-date-picker-range-dialog/filter-date-picker-range-dialog.component";
import {
    FilterInputMultipleDialogComponent,
} from "./filter-input-multiple-dialog/filter-input-multiple-dialog.component";
import {FilterRelationDialogComponent} from "./filter-relation-dialog/filter-relation-dialog.component";
import {FilterChoiceDialogComponent} from "./filter-choice-dialog/filter-choice-dialog.component";
import {
    FilterChoiceMultipleDialogComponent,
} from "./filter-choice-multiple-dialog/filter-choice-multiple-dialog.component";
import {
    FilterRelationMultipleDialogComponent,
} from "./filter-relation-multiple-dialog/filter-relation-multiple-dialog.component";
import {ToastService} from "../../services/toast.service";
import {DialogComponent} from "../dialog/dialog.component";
import {TranslateService} from "../../services/translate.service";
import {BaseService} from "../../services/base.service";
import {ModelBase} from "../../models/model-base";
import {HttpClient} from "@angular/common/http";

type FilterDialogRef =
    | FilterInputMultipleDialogComponent
    | FilterInputRangeDialogComponent
    | FilterInputDialogComponent
    | FilterDatePickerRangeDialogComponent
    | FilterDatePickerDialogComponent
    | FilterRelationDialogComponent
    | FilterRelationMultipleDialogComponent
    | FilterChoiceDialogComponent
    | FilterChoiceMultipleDialogComponent;

export interface ConfigDialog {
    data: FilterConfiguration;
    minWidth: string;
    maxWidth: string;
    maxHeight: string;
}

export interface ParametersFilter {
    [key: string]: any;
}

@Component({
    selector: "app-filter",
    templateUrl: "./filter.component.html",
    styleUrls: ["./filter.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class FilterComponent implements OnDestroy, AfterViewInit {
    public unsubscribe = new Subject();
    @Input() configurations: FilterConfiguration[];
    @Input() widthModalFilter?: string = "30%";
    @Input() maxWidthModalFilter?: string = "60rem";
    @Input() heightModalFilter?: string = "auto";
    @Input() searchOnInit ?: boolean = true;
    @Output() addFilterEvent: Subject<void> = new Subject<void>();
    @Output() removeFilterEvent: Subject<void> = new Subject<void>();
    @Output() searchFilterEvent: Subject<void> = new Subject<void>();
    public selectedFilters: SelectedFilter[] = [];
    readonly addOnBlur = true;
    readonly separatorKeysCodes = [ENTER, COMMA] as const;
    protected readonly FieldType = FieldType;
    public service: BaseService<ModelBase>;
    public filterBooleanControls: Map<string, boolean> = new Map();

    constructor(public dialog: MatDialog, public translate: TranslateService, public toast: ToastService, public http: HttpClient, public injector: Injector,) {
    }

    ngAfterViewInit() {
        this.loadFilterBooleanControls();
        if (this.searchOnInit) {
            this.applyCurrentFilters();
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next({});
        this.unsubscribe.complete();
    }

    private loadFilterBooleanControls(): void {
        this.configurations.filter(x => x.fieldType == FieldType.BOOLEAN).forEach(item => {
            if (!item.defaultValues) {
                this.filterBooleanControls.set(item.name, null);
            } else {
                this.filterBooleanControls.set(item.name, item.defaultValues[0] as boolean);
            }
        });
    }

    public remove(selectedFilter: SelectedFilter): void {
        const index = this.selectedFilters.findIndex(x => x.name === selectedFilter.name);
        if (index > -1) {
            this.selectedFilters.splice(index, 1);
            this.filterBooleanControls.set(selectedFilter.name, null);
            this.removeFilterEvent.next();
            this.searchFilterEvent.next();
        }
    }

    private isFilterAlreadySelected(configuration: FilterConfiguration): boolean {
        return this.selectedFilters.some((item) => item.label === configuration.label);
    }

    public add(configuration: FilterConfiguration): void {
        if (this.isFilterAlreadySelected(configuration)) {
            this.toast.info(this.translate._("warning"), this.translate._("filter-already-selected"));
            return;
        }
        this.openDialogFilter(configuration);
    }

    public editFilter(filter: FilterConfiguration): void {
        this.openDialogFilter(filter);
    }

    private openDialogFilter(configuration: FilterConfiguration): void {
        const dialogRef = this.getDialogRef(configuration);
        dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe((selectedFilter: SelectedFilter) => {
            if (selectedFilter) {
                this.updateSelectedFilters(selectedFilter);
                this.addFilterEvent.next();
                this.searchFilterEvent.next();
            }
        });
    }

    private getDialogRef(configuration: FilterConfiguration): MatDialogRef<FilterDialogRef> {
        const configs = {
            data: configuration,
            minWidth: this.widthModalFilter,
            maxWidth: this.maxWidthModalFilter,
            maxHeight: this.heightModalFilter,
        };
        switch (configuration.fieldType) {
            case FieldType.STRING:
                return this.getNumberOrStringDialogRef(configs);
            case FieldType.NUMBER:
                return this.getNumberOrStringDialogRef(configs);
            case FieldType.DATE:
                return this.getDateDialogRef(configs);
            case FieldType.RELATION:
                return this.getRelationDialogRef(configs);
            case FieldType.CHOICE:
                return this.getChoiceDialogRef(configs);
            default:
                throw new Error("No support configuration");
        }
    }

    private getNumberOrStringDialogRef(configs: ConfigDialog): MatDialogRef<FilterDialogRef> {
        switch (configs.data.filterType) {
            case FilterType.MULTIPLE:
                return this.dialog.open(FilterInputMultipleDialogComponent, configs);
            case FilterType.RANGE:
                return this.dialog.open(FilterInputRangeDialogComponent, configs);
            default:
                return this.dialog.open(FilterInputDialogComponent, configs);
        }
    }

    private getDateDialogRef(configs: ConfigDialog): MatDialogRef<FilterDialogRef> {
        switch (configs.data.filterType) {
            case FilterType.RANGE:
                return this.dialog.open(FilterDatePickerRangeDialogComponent, configs);
            default:
                return this.dialog.open(FilterDatePickerDialogComponent, configs);
        }
    }

    private getRelationDialogRef(configs: ConfigDialog): MatDialogRef<FilterDialogRef> {
        configs["model"] = configs.data.relationConfiguration.model;
        configs["urlEndpoint"] = configs.data.relationConfiguration.urlEndpoint;
        switch (configs.data.filterType) {
            case FilterType.NORMAL:
                return this.dialog.open(FilterRelationDialogComponent, configs);
            default:
                return this.dialog.open(FilterRelationMultipleDialogComponent, configs);
        }
    }

    private getChoiceDialogRef(configs: ConfigDialog): MatDialogRef<FilterDialogRef> {
        configs["model"] = configs.data.choiceConfiguration.model;
        configs["urlEndpoint"] = configs.data.choiceConfiguration.urlEndpoint;
        switch (configs.data.filterType) {
            case FilterType.NORMAL:
                return this.dialog.open(FilterChoiceDialogComponent, configs);
            default:
                return this.dialog.open(FilterChoiceMultipleDialogComponent, configs);
        }
    }

    private updateSelectedFilters(selectedFilter: SelectedFilter): void {
        const filterIndex = this.selectedFilters.findIndex((filter: SelectedFilter) => filter.name === selectedFilter.name,);
        if (filterIndex > -1) {
            this.selectedFilters[filterIndex] = selectedFilter;
        } else {
            this.selectedFilters.push(selectedFilter);
        }
    }

    public getValueInputMultiple(selectedFilter: SelectedFilter): string {
        return this.getValuesString(selectedFilter, " ou ");
    }

    public getValueChoiceOrRelationMultiple(selectedFilter: SelectedFilter): string {
        return this.getValuesString(selectedFilter, " ou ", "display");
    }

    private getValuesString(selectedFilter: SelectedFilter, separator: string, key: string = "value"): string {
        if (Array.isArray(selectedFilter.result)) {
            return selectedFilter.result.map((filterResult) => filterResult[key]).join(separator);
        }
        return "";
    }

    public clearFilters(): void {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: "600px",
            data: {title: this.translate._("clear-filter"), message: this.translate._("delete-filter-confirm"),},
        });
        dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
            if (value) {
                this.selectedFilters = [];
                this.filterBooleanControls = new Map();
                this.removeFilterEvent.next();
                this.searchFilterEvent.next();
            }
        });
    }

    public addBooleanFilter(conf: FilterConfiguration, yesOrNo: boolean) {
        if (this.selectedFilters.some(x => x.name == conf.name)) {
            const index = this.selectedFilters.findIndex(x => x.name == conf.name);
            this.selectedFilters.splice(index, 1);
        }
        this.addSelectedFilter(yesOrNo, conf);
    }

    private addSelectedFilter(yesOrNo: boolean, conf: FilterConfiguration) {
        const filterResult = new FilterResult();
        filterResult.value = yesOrNo;
        const selectedFilter = new SelectedFilter();
        Object.assign(selectedFilter, conf);
        selectedFilter.result = filterResult;
        this.selectedFilters.push(selectedFilter);
        this.filterBooleanControls.set(conf.name, yesOrNo);
        this.addFilterEvent.next();
        this.searchFilterEvent.next();
    }

    public applyCurrentFilters(): void {
        if (this.configurations.length > 0) {
            this.configurations.forEach((filter) => {
                if (filter.defaultValues) {
                    this.addCurrentFilters(filter);
                }
            });
            this.addFilterEvent.next();
            this.searchFilterEvent.next();
        }
    }

    public addCurrentFilters(filter: BaseFilter): void {
        const selectedFilter = new SelectedFilter();
        selectedFilter.filterType = filter.filterType;
        selectedFilter.fieldType = filter.fieldType;
        selectedFilter.label = filter.label;
        selectedFilter.name = filter.name;
        selectedFilter.relationConfiguration = filter.relationConfiguration;
        selectedFilter.labelsBoolean = filter.labelsBoolean;
        const createFilterResult = (value: string | string[] | Date | number | number[] | boolean | NumberRange | DateRange) => {
            const filterResult = new FilterResult();
            let display = `${value}`;
            if (filter.fieldType === FieldType.CHOICE) {
                const service = this.createService(filter.choiceConfiguration.model, filter.choiceConfiguration.urlEndpoint);

                service.getChoices(filter.name).pipe(takeUntil(this.unsubscribe)).subscribe(choices => {
                    const foundChoice = choices.find(c => c.value == value);
                    display = foundChoice ? foundChoice.display_name : display;
                    filterResult.display = display;
                });
            }
            filterResult.value = value;
            filterResult.display = display;
            return filterResult;
        };

        if (filter.filterType === FilterType.MULTIPLE) {
            selectedFilter.result = filter.defaultValues?.map(createFilterResult) || [];
        } else {
            selectedFilter.result = filter.defaultValues?.length ? createFilterResult(filter.defaultValues[0]) : null;
        }
        this.selectedFilters.push(selectedFilter);
    }

    public getParametersFilter(): ParametersFilter {
        const params: ParametersFilter = {};
        this.selectedFilters.forEach((selectedFilter) => {
            if (selectedFilter.filterType == FilterType.RANGE) {
                if (selectedFilter.fieldType == FieldType.NUMBER) {
                    const result = selectedFilter.result as FilterResult;
                    const value = result.value as NumberRange;
                    params[`${selectedFilter.name}_min`] = value.start;
                    params[`${selectedFilter.name}_max`] = value.end;
                } else if (selectedFilter.fieldType == FieldType.DATE) {
                    const result = selectedFilter.result as FilterResult;
                    const value = result.value as DateRange;
                    params[`${selectedFilter.name}_after`] = value.start;
                    params[`${selectedFilter.name}_before`] = value.end;
                }
            } else if (selectedFilter.filterType == FilterType.NORMAL) {
                const result = selectedFilter.result as FilterResult;
                params[selectedFilter.name] = result.value;
            } else if (selectedFilter.filterType == FilterType.MULTIPLE) {
                const result = selectedFilter.result as FilterResult[];
                params[selectedFilter.name] = result.map((item) => item.value).join(",");
            }
        });
        return params;
    }

    public createService<K>(model: new () => K, path: string): BaseService<K> {
        const TOKEN = new InjectionToken<BaseService<K>>("service_" + path, {
            providedIn: "root",
            factory: () => new BaseService<K>(this.http, path),
        });
        return this.injector.get(TOKEN);
    }
}
