import {Component, Injector, OnInit} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {MainService} from "../main/main.service";
import {AuthService} from "../../../services/auth.service";
import {CustomValidators} from "../../../utilities/validator/custom-validators";
import {BaseComponentDirective, BaseOptions} from "../../base-component.directive";
import {ToastService} from "../../../services/toast.service";
import {URLS} from "../../../app/app.urls";
import {Code} from "../../../models/account/code";
import {takeUntil} from "rxjs/operators";

const BASE_OPTIONS: BaseOptions = {
    endpoint: URLS.CODE,
    retrieveOnInit: true
};

@Component({
    selector: "app-forgot-password",
    templateUrl: "./forgot-password.component.html",
    styleUrls: ["./forgot-password.component.scss"]
})
export class ForgotPasswordComponent extends BaseComponentDirective<Code> implements OnInit {
    public formGroup: UntypedFormGroup;
    public showSecondComponent = false;


    constructor(
        public mainService: MainService,
        public toast: ToastService,
        public authService: AuthService,
        public formBuilder: UntypedFormBuilder,
        public injector: Injector,
    ) {
        super(injector, BASE_OPTIONS);
    }


    public createFormGroup(): void {
        this.formGroup = this.formBuilder.group({
            username: [null, CustomValidators.required],

        });
    }

    public sendCode() {
        this.service.clearParameter();
        const payload = {
            username: this.v.username,
        };
        this.service.postFromListRoute("send_code", payload)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.toast.success("user", "email-sent-successfully");
                this.showSecondComponent = true;
                this.mainService.userRecoveryPassword = this.v.username;
            });
    }


}
