import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({ standalone: true, name: "tooLong" })
export class ChangeTooLong implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(value: string): string {
        // Check the length of the plain text and truncate if necessary
        if (value.length > 30) {
            return value.substring(0, 30) + "...";
        }

        return value;
    }
}
