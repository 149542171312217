export class AdvancedFilter {
    name: string;
    filters: string[];
    display_filters: string[];
    constructor(name: string, filters: string[] = [], display_filters: string[] = []) {
        this.name = name;
        this.filters = filters;
        this.display_filters = display_filters;
    }
}
