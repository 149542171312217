import {Directive, ElementRef, Input, OnInit, Renderer2} from "@angular/core";


@Directive({
    standalone: true,
    selector: "[fpfLayout]"
})
export class LayoutDirective implements OnInit {

  @Input("fpfLayout") layout: string = "row";
  @Input("fpfLayoutAlign") align: string = "";
  @Input("fpfLayoutGap") layoutGap: string = "";
  @Input("fpfWrap") wrap: string = "";
  @Input("fpfFill") fill: boolean = false;
  @Input("fpfFOffSet") offset: string = "";
  @Input("fpfOrder") order: string = "";

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit() {

    const leftOffset = this.offset || "0";

    if (this.wrap !== "") {
      this.renderer.addClass(this.el.nativeElement, `flex-${this.wrap}`);
    }

    if (this.layoutGap) {
      if (this.layoutGap.includes("row") || this.layoutGap.includes("column")) {
        this.renderer.addClass(this.el.nativeElement, `${this.layoutGap}`);
      } else {
        this.renderer.addClass(this.el.nativeElement, `gap-${this.layoutGap}`);
      }
    }
    if (this.layout !== ""){
      this.renderer.setStyle(this.el.nativeElement, "display", "flex");
      this.renderer.addClass(this.el.nativeElement, `flex-${this.layout}`);
    }

    if(this.align !== "") {
        this.renderer.setStyle(this.el.nativeElement, "display", "flex");
        const alignValues = this.align.split(" ");

      if (alignValues) {
        this.renderer.addClass(this.el.nativeElement, `align-items-${alignValues[0]}`);
        if (alignValues[1]) {
          this.renderer.addClass(this.el.nativeElement, `justify-content-${alignValues[1]}`);
        }
        this.renderer.addClass(this.el.nativeElement, `align-content-${alignValues[0]}`);
      }
    }

    if(this.fill){
      this.renderer.setStyle(this.el.nativeElement,"height", "100%");
      this.renderer.setStyle(this.el.nativeElement,"min-height", "100%");
      this.renderer.setStyle(this.el.nativeElement,"min-width", "100%");
      this.renderer.setStyle(this.el.nativeElement,"width", "100%");
    }

    if (this.offset){
      this.renderer.setStyle(this.el.nativeElement,"margin-left", leftOffset);
    }

    if(this.order){
      this.renderer.setStyle(this.el.nativeElement,"order", this.order);
    }

  }
}
