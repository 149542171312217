import {Pipe, PipeTransform} from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({standalone: true, name: "removeHtmlTags"})
export class RemoveHtmlTagsPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(value: string): string {
        // Since the result is now a plain string, there's no need to use bypassSecurityTrustHtml
        return value.replace(/<\/?[^>]+(>|$)/g, "");
    }
}
