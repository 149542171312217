import {Component, Inject, InjectionToken, Injector, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FilterConfiguration, FilterResult, SelectedFilter} from "../filter-configuration";
import {FormBuilder, FormGroup} from "@angular/forms";
import {PaginatedResult} from "../../../dto/paginated-result";
import {takeUntil} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {Subject} from "rxjs";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {ModelBase} from "../../../models/model-base";
import {BaseService} from "../../../services/base.service";
import {AutocompleteComponent} from "../../autocomplete/autocomplete.component";
import {AESEncryptDecryptService} from "../../../services/crypto.service";
import {CustomValidators} from "../../../utilities/validator/custom-validators";
import {AutocompleteEvent} from "../../autocomplete/autocomplete-control.component";



@Component({
    selector: "app-filter-relation-multiple-dialog",
    templateUrl: "./filter-relation-multiple-dialog.component.html",
    styleUrls: ["./filter-relation-multiple-dialog.component.scss"]
})
export class FilterRelationMultipleDialogComponent implements OnInit, OnDestroy {
    public unsubscribe = new Subject();
    public formGroup: FormGroup;
    public items: PaginatedResult<ModelBase> = new PaginatedResult<ModelBase>();
    public service: BaseService<ModelBase>;

    @ViewChild(AutocompleteComponent, {static: true})
    public appAutoComplete: AutocompleteComponent;

    public filterResults: FilterResult[] = [];
    readonly addOnBlur = true;
    readonly separatorKeysCodes = [ENTER, COMMA] as const;


    constructor(public injector: Injector,
                public http: HttpClient,
                public CryptService: AESEncryptDecryptService,
                @Inject(MAT_DIALOG_DATA) public data: FilterConfiguration,
                public dialogRef: MatDialogRef<any>,
                public formBuilder: FormBuilder) {
        this.service = this.createService(data.relationConfiguration.model, data.relationConfiguration.urlEndpoint);
    }


    ngOnInit() {
        this.createFormGroup();
    }

    public createService<K>(model: new () => K, path: string): BaseService<K> {
        const TOKEN = new InjectionToken<BaseService<K>>("service_" + path, {
            providedIn: "root", factory: () => new BaseService<K>(this.http, path),
        });
        return this.injector.get(TOKEN);
    }


    public createFormGroup(): void {
        this.formGroup = this.formBuilder.group({
            value: [null, CustomValidators.required]
        });
    }

    public ok(): void {
        const selectedFilter = new SelectedFilter();
        Object.assign(selectedFilter, this.data);

        selectedFilter.result = this.filterResults;

        this.dialogRef.close(selectedFilter);
    }

    public getItems(event: AutocompleteEvent): void {
        this.service.clearParameter();
        this.service.addParameter("limit", event.limit);
        this.service.addParameter("offset", event.offset);
        this.service.addParameter(this.data.relationConfiguration.fieldSearch, event.searchText);
        if (this.data.relationConfiguration.onlyActiveRecords) {
            this.service.addParameter("active", true);
        }
        this.service.getPaginated()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(response => this.items = response);
    }

    public add(): void {
        const value = this.formGroup.controls.value.value;

        const index = this.filterResults.findIndex((filterResult: FilterResult) => filterResult.value === value);
        if (index >= 0) {
            return;
        }

        const display = this.appAutoComplete.item[this.data.relationConfiguration.display];

        const filterResult = new FilterResult();
        filterResult.value = value;
        filterResult.display = display;

        this.filterResults.push(filterResult);
        this.formGroup.reset();
    }

    public remove(_filterResult: FilterResult): void {
        const index = this.filterResults.findIndex((filterResult: FilterResult) => filterResult.value === _filterResult.value);
        this.filterResults.splice(index, 1);
    }

    ngOnDestroy() {
        this.unsubscribe.next({});
        this.unsubscribe.complete();
    }

}
