<div fpfLayout="row" fpfFlex fpfLayoutGap="2" class="m-2" fpfLayoutAlign="center between ">
    <div fpfLayout="row" fpfLayoutGap="2">
        <app-image-user [isProfile]="true" [avatar]="targets['avatar_minio']"></app-image-user>
        <h2 fpfLayout="column" fpfLayoutAlign="start center">
            <strong>{{ targets['name'] }}</strong>
            <h3><strong>{{ targets['role_type'] }}</strong></h3>
            <span class="skill">{{ targets['skill_name'] }}</span>
        </h2>
    </div>
    <div fpfLayout="row" fpfLayoutGap="2" fpfFlex="10%" fpfLayoutAlign="start center">
        @for(item of iconTargets; track item) {
                <div fpfLayout fpfFlex="33%">
                    @if (targets[item.condition]) {
                        <mat-icon
                            fpfLayout
                            [ngClass]="item.class"
                            color="accent"
                            matTooltip="{{ item.tooltip | translate}}">
                        {{item.icon}}
                        </mat-icon>
                    }
                </div>
        }
    </div>
</div>
