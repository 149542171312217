import {Component, Injector, OnInit} from "@angular/core";
import {BaseComponentDirective, BaseOptions} from "../../../base-component.directive";
import {Vacancy} from "../../../../models/rh/vacancy";
import {MainService} from "../../main/main.service";
import {URLS} from "../../../../app/app.urls";
import {VacancySkill} from "../../../../models/rh/vacancy-skill";
import {BaseService} from "../../../../services/base.service";
import {takeUntil} from "rxjs/operators";

const BASE_OPTIONS : BaseOptions = {
    pk: "id",
    endpoint: URLS.VACANCY,
    retrieveOnInit: true,
    paramsOnInit: {"expand": "function,role_type"},
};

@Component({
    selector: "app-external-vacancies-item",
    templateUrl: "./external-vacancies-item.component.html",
    styleUrls: ["./external-vacancies-item.component.scss"]
})
export class ExternalVacanciesItemComponent extends BaseComponentDirective<Vacancy> implements OnInit {

    public object: Vacancy = new Vacancy();

    public vacancySkillsService: BaseService<VacancySkill>;
    public vacancySkillsDesirable: VacancySkill[] = [];
    public vacancySkillsMandatory: VacancySkill[] = [];


    constructor(public mainService: MainService,
                public injector: Injector,
    ) {
        super(injector, BASE_OPTIONS);
        this.vacancySkillsService = this.createService(VacancySkill, URLS.VACANCY_SKILL);

    }

    ngOnInit() {
        if (this.object.id === undefined) {
            this.object.id = null;
        }
        super.ngOnInit();
        this.getDesirableSkills();
        this.getMandatorySkills();
    }

    createFormGroup(): void {
    }

    public getDesirableSkills(): void {
        this.vacancySkillsService.addParameter("vacancy_id", this.object.id);
        this.vacancySkillsService.addParameter("required", false);
        this.vacancySkillsService.addParameter("expand", "skill");
        this.vacancySkillsService.getFromListRoute("list_skills")
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                // @ts-ignore
                this.vacancySkillsDesirable = response;
            });
    }

    public getMandatorySkills(): void {
        this.vacancySkillsService.addParameter("vacancy_id", this.object.id);
        this.vacancySkillsService.addParameter("required", true);
        this.vacancySkillsService.addParameter("expand", "skill");
        this.vacancySkillsService.getFromListRoute("list_skills")
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response) => {
                // @ts-ignore
                this.vacancySkillsMandatory = response;
            });
    }

}
