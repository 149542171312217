import {Injectable, OnDestroy} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {URLS} from "../app/app.urls";
import {CommonsService} from "./commons.service";
import {Subject} from "rxjs";
import {Observable} from "rxjs/internal/Observable";
import {Settings} from "../models/settings/settings";

@Injectable()
export class VersionService extends CommonsService implements OnDestroy {

    public unsubscribe: Subject<void> = new Subject<void>();

    constructor(public http: HttpClient) {
        super();
    }

    public get(key: string): Observable<Settings[]> {
        this.clearParameter();
        this.addParameter("name", key);
        return this.http.get<Settings[]>(`${this.urlBase}${URLS.SETTINGS}`, this.getOptions());
    }


    ngOnDestroy(): void {
        this.unsubscribe.complete();
    }


}
