import {BrowserModule, DomSanitizer} from "@angular/platform-browser";
import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from "@angular/core";
import {HashLocationStrategy, LocationStrategy, registerLocaleData} from "@angular/common";

import localePt from "@angular/common/locales/pt";
import localeEn from "@angular/common/locales/en";
import {ROUTES} from "./app.routing";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {RouterModule} from "@angular/router";
import {SharedModule} from "./shared.module";
import {LANGUAGE, LANGUAGES, TranslateService} from "./services/translate.service";
import {MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig} from "@angular/material/dialog";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";
import {MatIconRegistry} from "@angular/material/icon";
import {MatPaginatorIntl, MatPaginatorModule} from "@angular/material/paginator";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ToastService} from "./services/toast.service";
import {APP_SWIPER_CONFIG, LOADER_OPTIONS, TOAST_OPTIONS, TRANSLATE_CONFIG} from "./app/app.constant";
import {AuthInterceptor} from "./utilities/auth.interceptor";
import {AppComponent} from "./app/app.component";
import {LoginComponent} from "./components/default/login/login.component";
import {PageNotfoundComponent} from "./components/default/page-notfound/page-notfound.component";
import {TranslateModule} from "@ngx-translate/core";
import {MainModule} from "./components/default/main/main.module";
import {TextMaskModule} from "angular2-text-mask";
import {SWIPER_CONFIG, SwiperModule} from "ngx-swiper-wrapper";
import {AppVariables} from "./app/app.variables";
import {ToastrModule} from "ngx-toastr";
import {NgxUiLoaderModule} from "ngx-ui-loader";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {CalendarModule, DateAdapter} from "angular-calendar";
import {adapterFactory} from "angular-calendar/date-adapters/date-fns";
import {CURRENCY_MASK_CONFIG, CurrencyMaskModule} from "ng2-currency-mask";
import {QuillModule} from "ngx-quill";
import {IConfig, provideEnvironmentNgxMask} from "ngx-mask";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {AESEncryptDecryptService} from "./services/crypto.service";
import {CreateUserCandidateComponent} from "./components/default/create-user-candidate/create-user-candidate.component";
import {LgpdTermDialogComponent} from "./components/default/lgpd-term-dialog/lgpd-term-dialog.component";
import {ProfileBadgeComponent} from "./components/default/profile-badge/profile-badge.component";
import {
    ProfileBadgeItemComponent
} from "./components/default/profile-badge/profile-badge-item/profile-badge-item.component";

import {ExternalVacancyComponent} from "./components/default/external-vacancy/external-vacancy.component";
import {
    ExternalVacanciesItemComponent
} from "./components/default/external-vacancy/external-vacancies-item/external-vacancies-item.component";
import {LayoutService} from "./services/layout.service";
import {CustomPaginatorIntl} from "./utilities/custom-paginator-intl";
import {NgxPaginationModule} from "ngx-pagination";
import {NgxEchartsModule} from "ngx-echarts";
import {NgProgressModule} from "ngx-progressbar";
import {RECAPTCHA_V3_SITE_KEY, RecaptchaModule, ReCaptchaV3Service} from "ng-recaptcha";
import {FlexLayoutDirective} from "./utilities/fpf-layout/flex-layout.directive";
import {LayoutDirective} from "./utilities/fpf-layout/layout.directive";
import {FlexDirective} from "./utilities/fpf-layout/flex.directive";
import {FlexWrapResponsiveDirective} from "./utilities/fpf-layout/fpf-layout-responsive/flex-wrap-responsive.directive";

import {
    FlexLayoutAlignResponsiveDirective
} from "./utilities/fpf-layout/fpf-layout-responsive/flex-layout-align-responsive.directive";
import {
    FlexLayoutGapResponsiveDirective
} from "./utilities/fpf-layout/fpf-layout-responsive/flex-layout-gap-responsive.directive";
import {
    FlexLayoutResponsiveDirective
} from "./utilities/fpf-layout/fpf-layout-responsive/flex-layout-responsive.directive";

import * as echarts from "echarts";
import {VersionService} from "./services/version.service";
import {DeleteConfirmDialogComponent} from "./components/default/delete-confirm-dialog/delete-confirm-dialog.component";
import {AngularD3CloudModule} from "angular-d3-cloud";

registerLocaleData(localePt, "pt");
registerLocaleData(localeEn, "en");

const maskConfig: Partial<IConfig> = {
    validation: false,
};


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ExternalVacancyComponent,
        ExternalVacanciesItemComponent,
        PageNotfoundComponent,
        CreateUserCandidateComponent,
        LgpdTermDialogComponent,
        ProfileBadgeComponent,
        ProfileBadgeItemComponent,
        DeleteConfirmDialogComponent
    ],
    imports: [
        SharedModule,
        BrowserModule,
        RecaptchaModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MainModule,
        MatPaginatorModule,
        NgProgressModule,
        MatProgressBarModule,
        TranslateModule.forRoot(TRANSLATE_CONFIG),
        SharedModule.forRoot(),
        RouterModule.forRoot(ROUTES),
        NgxUiLoaderModule.forRoot(LOADER_OPTIONS),
        ToastrModule.forRoot(TOAST_OPTIONS),
        CalendarModule.forRoot({provide: DateAdapter, useFactory: adapterFactory}),
        QuillModule.forRoot({
            modules: {
                syntax: false,
                toolbar: [
                    ["bold", "italic", "underline"],
                    [{"list": "ordered"}, {"list": "bullet"}],
                    [{"script": "sub"}, {"script": "super"}],
                    [{"color": []}, {"background": []}],
                    ["clean"],
                ]
            }
        }),
        MatAutocompleteModule,
        CurrencyMaskModule,
        NgxPaginationModule,
        FlexLayoutDirective,
        FlexDirective,
        LayoutDirective,
        FlexWrapResponsiveDirective,
        FlexLayoutAlignResponsiveDirective,
        FlexLayoutGapResponsiveDirective,
        FlexLayoutResponsiveDirective,
        NgxEchartsModule.forRoot({
            echarts,
        }),
        AngularD3CloudModule,
    ],
    providers: [
        AppVariables,
        ToastService,
        AESEncryptDecryptService,
        MatDialogConfig,
        ReCaptchaV3Service,
        SwiperModule,
        TextMaskModule,
        NgProgressModule,
        InfiniteScrollModule,
        NgxEchartsModule,
        ProfileBadgeItemComponent,
        ReCaptchaV3Service,
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: "<6LeS1BApAAAAAIiL6jrGA0RcaFaFG7De3avwlrre>"
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: CURRENCY_MASK_CONFIG,
            deps: [TranslateService],
            useFactory: (translate: TranslateService) => translate.getCurrencyConfig()
        },
        {
            provide: LOCALE_ID,
            deps: [TranslateService],
            useFactory: (translateService: TranslateService) => translateService.currentLang
        },
        {
            provide: MAT_DATE_LOCALE,
            deps: [TranslateService],
            useFactory: (translateService: TranslateService) => translateService.currentLang
        },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {
            provide: MatPaginatorIntl,
            useClass: CustomPaginatorIntl,
        },
        {
            provide: SWIPER_CONFIG,
            useValue: APP_SWIPER_CONFIG
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {hasBackdrop: true, autoFocus: false}
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {appearance: "outline"}
        },
        {
            provide: LOCALE_ID,
            deps: [TranslateService],
            useFactory: (translateService: TranslateService) => translateService.currentLang
        },
        {
            provide: MAT_DATE_LOCALE,
            deps: [TranslateService],
            useFactory: (translateService: TranslateService) => translateService.currentLang
        },
        {
            provide: CURRENCY_MASK_CONFIG,
            deps: [TranslateService],
            useFactory: (translateService: TranslateService) => translateService.getCurrencyConfig()
        },
        provideEnvironmentNgxMask(maskConfig),
        LayoutService,
        VersionService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})

export class AppModule {

    constructor(
        //private _adapter: DateAdapter<any>,
        private _translate: TranslateService,
        private domSanitizer: DomSanitizer,
        private _matIconRegistry: MatIconRegistry) {

        // this._adapter.setLocale(LANGUAGE.PT_BR);
        this._translate.addLangs(LANGUAGES);
        this._translate.setDefaultLang(LANGUAGE.PT_BR);
        this._translate.use(this._translate.currentLang);

        this._matIconRegistry.registerFontClassAlias("fas", "fas");
        this._matIconRegistry.addSvgIcon(
            "linkedin", this.domSanitizer.bypassSecurityTrustResourceUrl(
                "../assets/images/icon-linkedin.svg"));
    }
}
