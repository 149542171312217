<div class="main-bg" [style.filter]="hover ? 'blur(9px)' : 'none'">
    <img class="logo-fpf" src="../../../../assets/images/profile-budge/logo-horizontal-fpftech-white.svg" height="52px"
         alt="Logo FPF Tech">
    <div class="container">
        <div class="align-card">
            <main class="content">
                <div class="my-informations">
                    <div class="image-mask">
                        @if (avatar) {
                            <img class="border" [src]="object.avatar_minio">
                        }
                        @if (!avatar) {
                            <img src="../../../../assets/images/bit-perfil.svg" alt="">
                        }
                    </div>
                    <h1>{{ object.name }}</h1>
                    <h3>{{ object?.current_role }}</h3>
                    <div class="email">
                        <img src="../../../../assets/images/profile-budge/envelope-regular.svg" alt="">
                        <span>{{ object.email }}</span>
                    </div>
                    <div class="separator"></div>
                </div>

                <div class="redirect">
                    <p>Siga-me nas redes sociais</p>
                    @if(object.github) {
                        <a class="social-media" target="_blank" href="{{object.github}}">
                            <img class="icon" src="../../../../assets/images/profile-budge/github.png">
                            <span class="name">Github</span>
                        </a>
                    }
                    @if(object.linkedin) {
                        <a class="social-media" target="_blank" href="{{object.linkedin}}">
                            <img class="icon" src="../../../../assets/images/profile-budge/linkedin.png">
                            <span class="name">Linkedin</span>
                        </a>
                    }
                    @if(object.telegram) {
                        <a class="social-media">
                            <img class="icon" src="../../../../assets/images/profile-budge/telegram.png">
                            <div class="telegram">
                                <span class="name">Telegram</span>
                                <span class="telegram-item">&#64;{{ object.telegram }}</span>
                            </div>
                        </a>
                    }
                </div>
            </main>
        </div>
    </div>
    <img class="logo-bit" src="../../../../assets/images/profile-budge/bit-fpftech.svg" height="100px" alt="Logo Bit">
</div>
