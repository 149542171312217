import {Component, Injector, OnInit} from "@angular/core";
import {BaseComponentDirective, BaseOptions} from "../../../base-component.directive";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {ToastService} from "../../../../services/toast.service";
import {URLS} from "../../../../app/app.urls";
import {takeUntil} from "rxjs/operators";
import {MainService} from "../../main/main.service";
import {Code} from "../../../../models/account/code";
import {CustomValidators} from "../../../../utilities/validator/custom-validators";

const BASE_OPTIONS: BaseOptions = {
    endpoint: URLS.CODE,
    retrieveOnInit: true
};

@Component({
    selector: "app-new-password",
    templateUrl: "./new-password.component.html",
    styleUrls: ["./new-password.component.scss"]
})
export class NewPasswordComponent extends BaseComponentDirective<Code> implements OnInit {
    public formGroup: UntypedFormGroup;
    public hide: boolean = true;

    constructor(
        public mainService: MainService,
        public toast: ToastService,
        public formBuilder: UntypedFormBuilder,
        public injector: Injector,
    ) {
        super(injector, BASE_OPTIONS);
    }

    public createFormGroup() {
        this.formGroup = this.formBuilder.group({
            password: [null, CustomValidators.compose([
                CustomValidators.required,
                CustomValidators.validPassword,
            ])],
            conf_password: [null, CustomValidators.validEqual("password")],
        });
    }

    public newPassword() {
        this.service.clearParameter();
        const payload = {
            username: this.mainService.userRecoveryPassword,
            password: this.v.password,
        };
        this.service.patchFromListRoute("set_new_password", payload)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.toast.success("new-password", "saved-password-successfully");
                this.goToPage("login");

            });
    }

}
