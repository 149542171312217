<mat-toolbar class="mat-dialog-header">
    <span class="w-full">{{ 'filter' | translate }} {{ 'by' | translate }} {{ data.label | translate }}</span>
    <button mat-icon-button [mat-dialog-close]="false">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content class="mat-typography">
    <form [formGroup]="formGroup" class="flex flex-column">
        <mat-form-field>
            <mat-label>{{ 'start-date' | translate }}</mat-label>
            <input matInput [matDatepicker]="start"
                   dateFormat="YYYY-MM-DD"
                   formControlName="start"
                   maxlength="=11"
                   required>
            <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
            <mat-datepicker #start></mat-datepicker>
            <mat-error>{{ 'the-field-is-mandatory' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'end-date' | translate }}</mat-label>
            <input matInput [matDatepicker]="end"
                   dateFormat="YYYY-MM-DD"
                   formControlName="end"
                   maxlength="11"
                   required
                   [min]="formGroup.value.start">
            <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
            <mat-datepicker #end></mat-datepicker>
            <mat-error validator [target]="'start-date'">{{ 'the-field-is-mandatory' | translate }}</mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end" class="actions-dialog">
    <button mat-raised-button color="accent" (click)="ok()" [disabled]="!formGroup.valid">
        {{ 'confirm' | translate }}
    </button>
</mat-dialog-actions>
