import {Component, Inject, Injector, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Module, ThemeColor} from "../../../models/account/module";
import {AuthService} from "../../../services/auth.service";
import {Router} from "@angular/router";
import {Subject} from "rxjs/internal/Subject";
import {User} from "../../../models/account/user";
import {HttpClient} from "@angular/common/http";

interface Result {
    user: User;
    selectedModule: Module;
    modules: Module[];
}

interface ThemeStrategy {
    getThemeColor(): string;
}

class ModuleThemeStrategy implements ThemeStrategy {
    constructor(private color: string) {
    }

    getThemeColor(): string {
        return this.color;
    }
}

class ThemeContext {
    private strategy: ThemeStrategy;

    constructor(strategy: ThemeStrategy) {
        this.strategy = strategy;
    }

    setStrategy(strategy: ThemeStrategy): void {
        this.strategy = strategy;
    }

    getThemeColor(): string {
        return this.strategy.getThemeColor();
    }
}

@Component({
    selector: "app-module-dialog",
    templateUrl: "./module-dialog.component.html",
    styleUrls: ["./module-dialog.component.scss"]
})
export class ModuleDialogComponent implements OnInit {
    public theme = "default-theme";

    public unsubscribe = new Subject();
    public backgroundColor: string;

    public modulesIcon: string[] = [];

    private themeContext: ThemeContext;
    public http: HttpClient;

    public modules: Module[] = [];
    public ThemeColor = ThemeColor;


    constructor(
        public injector: Injector,
        public authService: AuthService,
        public router: Router,
        public dialogRef: MatDialogRef<ModuleDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Result
    ) {
        this.themeContext = new ThemeContext(new ModuleThemeStrategy("l#4CAE50"));
        this.http = injector.get(HttpClient);
    }

    ngOnInit(): void {
        this.modules = this.data.modules;
        this.setIcons();
        this.setThemeStrategy(this.data.selectedModule.description);
        this.backgroundColor = this.themeContext.getThemeColor();
    }


    public setIcons() {
        this.modules.forEach(module => {
            this.modulesIcon[module.description] = "assets/images/icon-module-" + module.description + ".svg";
        });
    }

    public changeModule(module: Module) {
        localStorage.setItem("selectedModule", JSON.stringify(module));
        this.router.navigate([module.default_route])
            .then(() => location.reload());
    }

    private setThemeStrategy(module: string): void {
        for (const [key, value] of Object.entries(this.ThemeColor)) {
            if (key.toLowerCase().replace("_", "-") === module) {
                this.themeContext.setStrategy(new ModuleThemeStrategy(value));
            }
        }
    }

}
