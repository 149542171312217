<div class="background">
    <section class="app-container">
        <header>
            <mat-toolbar id="header-vacancies">
                <img alt="" src="assets/images/logo-bit-vert.svg" height="32px">
            </mat-toolbar>
        </header>
        <section class="content-container">
            <div fpfLayout="row" fpfLayoutAlign="center start" class="ml-3">
                <h3 class="length-vacancy">{{ vacancyList.length }}</h3>
                <span class="vacancies-title">{{ 'vacancies' | translate }}</span>
                <span>{{ 'available' | translate }}</span>
            </div>

            @for (vacancy of vacancyList; track vacancy) {
                <div
                    fpfLayout fpfLayoutAlign="start center">
                    <mat-card class="example-card" (click)="goToPage('/vacancies_item/' + vacancy.id)">
                        <mat-card-header>
                            <mat-card-title class="size-function">{{ vacancy.function["name"] }}
                                ({{ vacancy.role_type['name'] }})
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content>

                            <div fpfLayout="row">
                                <div fpfLayout="row" fpfLayoutGap="4" fpfLayoutAlign="center center" class="mt-4 text-base">

                                    <div fpfLayout="row" fpfLayoutGap="1">
                                        <mat-icon>work_outline</mat-icon>
                                        <p>FPF Tech</p>
                                    </div>

                                    <div fpfLayout="row" fpfLayoutGap="1">
                                        <mat-icon>room</mat-icon>
                                        <p>Manaus-AM</p>
                                    </div>

                                    <div fpfLayout="row" fpfLayoutGap="1">
                                        @if (vacancy.job_modality === 'RM') {
                                            <mat-icon>home</mat-icon>
                                        }
                                        @if (vacancy.job_modality === 'HB') {
                                            <mat-icon>transfer_within_a_station</mat-icon>
                                        }
                                        @if (vacancy.job_modality === 'WIP') {
                                            <mat-icon>domain</mat-icon>
                                        }
                                        <p>{{ vacancy.display_job_modality }}</p>
                                    </div>
                                </div>
                            </div>
                            <mat-card-actions fpfLayout fpfLayoutAlign="center end">
                                <button mat-flat-button color="accent" class="text-white"
                                        (click)="goToPage('/vacancies_item/' + vacancy.id)">
                                    Saiba mais
                                </button>
                            </mat-card-actions>
                        </mat-card-content>
                    </mat-card>
                </div>
            }

        </section>

        <footer class="footer">
            <img alt="Logo" src="assets/images/logo-fpf-branco.svg" height="40px"/>
            <br>
            <span> 2023 © FPF Tech - Todos os direitos reservados.</span>
            <p> Av. Danilo de Matos Areosa, 1170 - Distrito Industrial. CEP: 69075-351 Manaus - AM / Brasil</p>

            <div fpfLayout="row" fpfLayoutAlign="center center">
                <a href="https://www.instagram.com/fpf.tech/">
                    <img src="assets/images/icon-instagram-white.svg" alt="Instagram" class="media-icons">
                </a>

                <a href="https://www.facebook.com/fpftech">
                    <img src="assets/images/icon-facebook-white.svg" alt="Facebook"
                         class="media-icons">
                </a>

                <a href="https://www.linkedin.com/company/fpf/">
                    <img src="assets/images/icon-linkedin.svg" alt="Linkedin"
                         class="media-icons">
                </a>

                <a href="https://www.youtube.com/channel/UCuQgGlz-lA4AH9cf3p0R3MQ?sub_confirmation=1">
                    <img src="assets/images/icon-youtube-white.svg" alt="Youtube"
                         class="media-icons">
                </a>
            </div>
        </footer>

    </section>
</div>
