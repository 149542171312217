<mat-toolbar class="mat-dialog-header">
    <span class="w-full">{{'filter' | translate}} {{'by' | translate}} {{data.label | translate}}</span>
    <button mat-icon-button [mat-dialog-close]="false">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>
    <form [formGroup]="formGroup" class="flex flex-column">
        <mat-form-field>
            <mat-label>{{data.label | translate}}</mat-label>
            <input matInput [matDatepicker]="value" dateFormat="YYYY-MM-DD"
                   formControlName="value">
            <mat-datepicker-toggle matSuffix [for]="value"></mat-datepicker-toggle>
            <mat-datepicker [touchUi]="false"
                            #value
                            panelClass="example-month-picker"
            ></mat-datepicker>
            <mat-error validator></mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end" class="actions-dialog">
    <button mat-raised-button color="accent" (click)="ok()" [disabled]="!formGroup.valid">
        {{'confirm' | translate}}
    </button>
</mat-dialog-actions>
