<div [fpfFill]="true" class="new-pass" fpfLayout="column" fpfLayoutAlign="center center" fpfLayoutGap="4">

    <form [formGroup]="formGroup">
        <mat-card fpfLayout fpfFlex="400px">
            <div mat-card-image fpfLayoutAlign="center center" fpfLayout="column">
                <div>
                    <img src="../../../../assets/images/bit-initial.svg" height="85px" style="margin-bottom: 10px"/>
                </div>
                <div>
                    <img src="assets/images/logo-fpf.svg" height="28px" class="logo-fpf" alt="FPF-Tech">
                </div>
            </div>


            <mat-card-content fpfLayout fpfLayoutAlign="center evenly" fpfLayoutGap="2">
                <div fpfLayout="column" fpfFlex="90" fpfLayoutGap="10px">
                    <strong>{{'new-password' | translate}}</strong>

                    <mat-form-field>
                        <mat-label>{{'password'| translate}}</mat-label>
                        <input matInput [type]="hide ? 'password' :'text'"
                               formControlName="password" required autoFocus
                               minlength="8"
                               maxlength="64"
                               autocomplete="off">
                        <mat-error validator>{{'the-field-is-mandatory' | translate}}</mat-error>
                        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            </mat-card-content>
            <mat-card-content fpfLayout fpfLayoutAlign="center evenly" fpfLayoutGap="2">
                <div fpfLayout="column" fpfFlex="90" fpfLayoutGap="10px">
                    <strong>{{'confirm-new-password' | translate}}</strong>

                    <mat-form-field>
                        <mat-label>{{'new-password'| translate}}</mat-label>
                        <input matInput [type]="hide ? 'password' :'text'"
                               formControlName="conf_password" required autoFocus
                               minlength="8"
                               maxlength="64"
                               autocomplete="off">
                        <mat-error validator>{{'the-field-is-mandatory' | translate}}</mat-error>
                        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            </mat-card-content>


            <mat-card-actions fpfLayout fpfLayoutAlign="center evenly" fpfLayoutGap="1">
                <div fpfLayout fpfLayoutAlign="center evenly" fpfLayoutGap="1"  class="box-align">
                    <button fpfFlex="40" mat-button mat-stroked-button class="cancel" type="button" (click)="goToPage('login')">
                        {{"cancel" | translate}}
                    </button>
                    <button fpfFlex="40" type="submit" class="submit" mat-button [disabled]="!formGroup.valid"
                    (click)="newPassword()">
                        {{'save' | translate}}
                    </button>
                </div>
            </mat-card-actions>
        </mat-card>


    </form>
</div>
