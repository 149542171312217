import {Directive, ElementRef, Input, OnInit, Renderer2} from "@angular/core";

@Directive({
    standalone: true,
    selector: "[fpfFlex]"
})
export class FlexDirective implements OnInit {

  @Input("fpfFlex") flexValue: string = "";

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }
  ngOnInit() {

    const flexValues = this.flexValue.split(" ");

    let flexGrow = flexValues[0] || "1";
    let flexShrink = flexValues[1] || "1";
    let flexBasis = flexValues[2] || "auto";

    switch (true) {
      case flexValues.some(value => value.includes("px") || value.includes("em") || value.includes("vh")):

        if (this.flexValue && flexValues.length === 1){
          flexBasis = this.flexValue;
          flexGrow = "1";
          flexShrink = "1";
          this.renderer.setStyle(this.el.nativeElement, "max-width", this.flexValue);
          this.renderer.setStyle(this.el.nativeElement, "min-width", this.flexValue);
        }
        this.renderer.setStyle(this.el.nativeElement, "flex", `${flexGrow} ${flexShrink} ${flexBasis}` );

        break;

      case flexValues.some(value => value.includes("calc")):

        this.renderer.setStyle(this.el.nativeElement, "flex", flexGrow + " " + flexShrink + " " + flexBasis);
        this.renderer.setStyle(this.el.nativeElement, "min-height", flexBasis);
        break;

      case flexValues.some(value => value.includes("%")):
        this.renderer.setStyle(this.el.nativeElement, "flex", "1 1 100%");
        this.renderer.setStyle(this.el.nativeElement, "box-sizing", "border-box");
        if (this.flexValue && flexValues.length === 1){
          this.renderer.setStyle(this.el.nativeElement, "max-width", this.flexValue);
        }
        break;

      case flexValues.length === 1 && flexBasis !== "auto":
        this.renderer.setStyle(this.el.nativeElement, "max-width", flexBasis);
        break;

      case flexValues.length === 1 && flexBasis === "auto":
        this.renderer.setStyle(this.el.nativeElement, "flex", "1 1 auto");
        break;

      case flexValues.length === 1 && flexBasis === "initial":
        this.renderer.setStyle(this.el.nativeElement, "flex", "1 1 auto");
        break;

      case flexBasis === "none":
        this.renderer.setStyle(this.el.nativeElement, "flex", "none");
        break;

      default:
        this.renderer.setStyle(this.el.nativeElement, "flex", "1 1 0%");
        this.renderer.setStyle(this.el.nativeElement, "box-sizing", "border-box");
        break;
    }
  }
}
