<mat-toolbar class="mat-dialog-header">
    <span class="w-full">{{'filter' | translate}} {{'by' | translate}} {{data.label | translate}}</span>
    <button mat-icon-button [mat-dialog-close]="false">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>
    <form [formGroup]="formGroup" class="flex flex-column">
        <mat-form-field>
            <mat-label>{{data.label | translate}}</mat-label>
            <input [appAutocomplete]="value" formControlName="value" matInput
                   required>
            <app-autocomplete #value
                              (changeEvent)="getItems($event)"
                              [display]="data.relationConfiguration.display"
                              [id]="data.relationConfiguration.pk"
                              [paginatedResult]="items">
            </app-autocomplete>
            <app-autocomplete-control [for]="value" [limit]="10" matSuffix></app-autocomplete-control>
            <mat-error validator></mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end" class="actions-dialog">
    <button mat-raised-button color="accent" (click)="ok()" [disabled]="!formGroup.valid">
        {{'confirm' | translate}}
    </button>
</mat-dialog-actions>
