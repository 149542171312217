<form [formGroup]="formGroup">
    <div fpfLayout="column" fpfLayoutAlign="center evenly" fpfLayoutGap="2">
        <div fpfLayout="column" [fpfFill]="true" fpfLayoutGap="3">
            <div class="ml-5 mr-3">
                <strong>{{'info-send-code-2' | translate}}</strong>
            </div>

            <div fpfLayout fpfLayoutAlign="center center">
                <mat-form-field fpfFlex="90%" fpfLayout>
                    <mat-label>{{'code'| translate}}</mat-label>
                    <input matInput type="text" maxlength="6" formControlName="code" required autoFocus autocomplete="off">
                    <mat-error validator>{{'the-field-is-mandatory' | translate}}</mat-error>
                </mat-form-field>
            </div>
        </div>
        <mat-card-actions [fpfFill]="true" fpfLayout="row" fpfLayoutAlign="center center" fpfLayoutGap="3">
            <div fpfLayout fpfLayoutAlign="center evenly"  fpfLayoutGap="1" class="box-align">
                <button fpfLayout fpfFlex="40" mat-button mat-stroked-button class="cancel" type="button" (click)="goToPage('login')">
                    {{"cancel" | translate}}
                </button>
                <button fpfLayout fpfFlex="40" type="submit" class="submit" mat-button [disabled]="!formGroup.valid"
                        (click)="verifyCode()">
                    {{'verify' | translate}}
                </button>
            </div>
        </mat-card-actions>
        <a (click)="rSendCode()">{{'rsend-email'| translate}}</a>
    </div>
</form>

