import {ModelBase} from "../../models/model-base";

export enum FieldType {
    STRING = "text",
    DATE = "date",
    NUMBER = "number",
    BOOLEAN = "boolean",
    RELATION = "relation",
    CHOICE = "choice",
}

export enum FilterType {
    NORMAL = "normal",
    RANGE = "range",
    MULTIPLE = "multiple",
}

export class BaseFilter {
    name: string;
    label: string;
    fieldType: FieldType;
    filterType: FilterType;
    relationConfiguration?: FilterRelationConfiguration;
    choiceConfiguration?: FilterChoiceConfiguration;
    defaultValues?: number[] | string[] | boolean[];
    labelsBoolean?: Map<boolean | null, string> = new Map<boolean | null, string>();
}

export class FilterRelationConfiguration {
    pk: string;
    display: string;
    fieldSearch: string;
    addParams?: object;
    onlyActiveRecords?: boolean = true;
    model: new () => ModelBase;
    urlEndpoint: string;
}

export class FilterChoiceConfiguration {
    choiceField: string;
    model: new () => ModelBase;
    urlEndpoint: string;
    removeOptions?: string[];
}

export class FilterConfiguration extends BaseFilter {
}

export class SelectedFilter extends BaseFilter {
    result: FilterResult | FilterResult[];
}

export class FilterResult {
    value: string | string[] | Date | number | number[] | boolean | NumberRange | DateRange;
    extraValue?: string | string[] | Date | number | number[] | boolean | NumberRange | DateRange;
    display?: string;
}

export class NumberRange {
    start: number;
    end: number;
}

export class DateRange {
    start: Date;
    end: Date;
}
