import {Directive, ElementRef, HostListener, Input, OnInit, Renderer2} from "@angular/core";

@Directive({
    standalone: true,
    selector: "[fpfFlexLayout]"
})
export class FlexLayoutDirective implements OnInit {

  @Input("fpfFlexLayout") layout: string = "row";
  @Input("fpfLayoutAlign") align: string = "";
  @Input("fpfFill") fill: boolean = false;
  @Input("fpfFlexWrap") wrap: boolean = false;
  @Input("fpfFlex") flexValue: string = "";
  @Input("fpfFlexFill") flexFill: boolean = false;
  @Input("fpfLayoutGap") layoutGap: string = "";


  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.renderer.setStyle(this.el.nativeElement, "display", "flex");
    this.renderer.setStyle(this.el.nativeElement, "flex-direction", this.layout);
    this.renderer.setStyle(this.el.nativeElement, "box-sizing", "border-box");

    const alignValues = this.align.split(" ");

    this.renderer.addClass(this.el.nativeElement, "flex");
    this.renderer.addClass(this.el.nativeElement, `flex-${this.layout}`);
    this.renderer.addClass(this.el.nativeElement, `justify-content-${alignValues[0]}`);
    this.renderer.addClass(this.el.nativeElement, `align-items-${alignValues[1]}`);

  }
}
