import {Directive, Injector, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {FilterComponent} from "../shared/filter/filter.component";
import {MatTableDataSource} from "@angular/material/table";
import {merge} from "rxjs";
import {map, switchMap, take, takeUntil, takeWhile, tap} from "rxjs/operators";
import {Observable} from "rxjs/internal/Observable";
import {PaginatedResult} from "../dto/paginated-result";
import {CdkDragDrop} from "@angular/cdk/drag-drop";
import {BaseComponentCommons, BaseOptionsCommons, csvFileName, handler} from "./base-component-commons";
import {Utils} from "../utilities/utils";
import {ActivatedRoute, Params} from "@angular/router";
import {DeleteConfirmDialogComponent} from "./default/delete-confirm-dialog/delete-confirm-dialog.component";


export interface BaseListComponentOptions extends BaseOptionsCommons{
    pageSize?: number;
    crossTable?: boolean;
    associative?: boolean;
    associativeRoute?: string;
    listenFilterComponent?: boolean;
    searchOnInit?: boolean;
    searchRoute?: string;
}

export const EVENT = {
    DELETE: 0,
    SEARCH: 1,
    TOGGLE: 2,
    REORDER: 3,
};

@Directive()
export abstract class BaseComponentListDirective<T> extends BaseComponentCommons<T> implements OnInit, OnDestroy {
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(FilterComponent, { static: false }) public filterComponent: FilterComponent;

    public dataSource: MatTableDataSource<T>;
    public displayedColumns = [];
    public pageLength = 0;
    public activatedRoute: ActivatedRoute;

    public isLoadTable = false;

    protected constructor(public injector: Injector, public options: BaseListComponentOptions) {
        super( injector, options);
        this.dataSource = new MatTableDataSource<T>();
        this.activatedRoute = injector.get(ActivatedRoute);
        this.pk = options.pk ?? "id";
    }


    ngOnInit(): void {
        super.ngOnInit();
        if (this.paginator) {
            this._createPaginator();
        }
        if (this.options.searchOnInit) {
            this.search();
        }
    }


    private _createPaginator(): void {
        if (this.paginator) {
            this.paginator.pageIndex = 0;
            this.paginator.pageSize = this.options.pageSize || 10;
            this.paginator.pageSizeOptions = [5, 10, 25, 50];
            this.paginator.showFirstLastButtons = true;

            if (this.sort) {
                // If the user changes the sort order, reset back to the first page.
                this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

                merge(this.sort.sortChange, this.paginator.page)
                    .pipe(tap(() => this.search()))
                    .subscribe();
            } else {
                this.paginator.page
                    .pipe(tap(() => this.search()))
                    .subscribe();
            }
        }
    }

    public search(restartIndex = false, callback?: (event: number) => void): void {
        const baseSearch = BaseComponentListDirective.prototype.search;
        if (this.search === baseSearch) {
            this.service.clearParameter();
        }


        //Paramenters Advanced Filter
        if (this.filterComponent) {
            this.service.addParameters(
                this.filterComponent.getParametersFilter()
            );
        }

        if (restartIndex && this.paginator) {
            this.paginator.pageIndex = 0;
        }

        if (this.options.paramsOnInit) {
            const parameters = this.options.paramsOnInit;
            Object.keys(parameters).forEach(t => this.service.addParameter(t, parameters[t]));
        }

        this.isLoadTable = false;
        this.beforeSearch()
            .subscribe(() => {
                if (this.options.associative) {
                    this._isAllAssociated();
                }

                handler(EVENT.SEARCH, callback);
                this.isLoadTable = true;
            });
    }

    private _isAllAssociated(): void {
        const data = this.dataSource.data;
        const filter = data.filter(t => t["associated"]);
        this.dataSource["allAssociated"] = data.length > 0 && data.length === filter.length;
    }


    // Toggle boolean fields
    public toggle(aObject: T | any, field: string, searchAfterUpdate = false, callback?: (event: number) => void): void {
        // Store field to patch
        const patch = {};
        patch[field] = aObject[field];

        // Update boolean field in object
        this.service
            .update(aObject[this.pk], patch)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.toast.success("success", "updated-successfully");
                    if (searchAfterUpdate) this.search();
                },
                error: () => {
                    aObject[field] = !aObject[field];
                },
                complete: () => {
                    handler(EVENT.TOGGLE, callback);
                },
            });
    }

    public stopPropagation(event: MouseEvent, stop = true) {
        event.preventDefault();
        if (stop) {
            event.stopPropagation();
        }
    }

    // Delete object
    public delete(pk: number, description?: string, data: object = {}, eventMouse?: MouseEvent, callback?: (event: number) => void): boolean {
        if (eventMouse) this.stopPropagation(eventMouse);
        // Create delete dialog reference
        const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
            width: "40vw",
            data: {
                id: pk,
                title: data["title"] ? data["title"] : "delete",
                message: data["message"] ? data["message"] : "delete-confirm",
                description: description ? description : "",
                confirmationButton: data["confirmationButton"] ? data["confirmationButton"] : "yes-delete",
            },
            disableClose: false,
        });

        dialogRef
            .afterClosed()
            .pipe(
                take(1),
                takeWhile((result) => result),
                switchMap(() => this.service.delete(pk)),
            )
            .subscribe(() => {
                this.toast.success("success-title", data["successMessage"] ? data["successMessage"] : "deleted-successfully");
                this.search();
                handler(EVENT.DELETE, callback);
            });

        return false;
    }

    // Export data to csv file
    public csvExport(route?: string, fileName?: string): void {
        this.main.spinner.start();
        this.service.loadFile(route || "export", {})
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(response => {
                Utils.downloadFileFromBlob(response, fileName || csvFileName(this.options.endpoint));
            }, () => null, () => this.main.spinner.stop());
    }

    public associate(source: number, target: number, associated: boolean, skill: number[] = []): void {
        const data = {
            "source": source,
            "target": target,
            "associated": associated,
            "skill": skill,
        };
        this.service.postFromListRoute(this.options.associativeRoute || "associate", data)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                if (source === 0) {
                    this.search();
                } else {
                    this._isAllAssociated();
                }
            });
    }

    public beforeSearch(): Observable<PaginatedResult<T> | T[]> {
        if (this.paginator) {
            this.service.addParameter("limit", this.paginator.pageSize);
            this.service.addParameter("offset", (this.paginator.pageIndex * this.paginator.pageSize));

            if (this.sort && this.sort.direction) {
                this.service.addParameter("ordering", this.sort.direction === "desc" ? `-${this.sort.active}` : this.sort.active);
            }

            return this.service.getPaginated(this.options.searchRoute).pipe(
                map((response: PaginatedResult<T>) => {
                    if (this.options.crossTable && response.header) {
                        this.displayedColumns = Object.keys(response.header);
                        this.dataSource["header"] = response.header;
                    }
                    this.pageLength = response.count;
                    this.dataSource.data = response.results;
                    return response;
                }),
            );
        } else {
            return this.service.getAll(this.options.searchRoute).pipe(
                map((response: T[]) => {
                    this.pageLength = response.length;
                    this.dataSource.data = response;
                    return response;
                }),
            );
        }
    }


    public reorder(event: CdkDragDrop<string[]>, callback?: (event: number) => void) {
        const item = this.dataSource.data[event.currentIndex];
        const itemMove = this.dataSource.data[event.previousIndex];
        this.service.clearParameter();
        this.service.patchFromDetailRoute(item[this.pk], "reorder", { "item_move": itemMove["url"] })
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.search();
                handler(EVENT.REORDER, callback);
            });
    }

}
