<ng-progress [min]="0.08"
             [max]="1"
             [speed]="300"
             [spinner]="true"
             [direction]="'ltr+'"
             [color]="'#ff5722'"
             [trickleSpeed]="300"
             [thick]="true"
             [ease]="'linear'">
</ng-progress>

<ngx-ui-loader></ngx-ui-loader>

<router-outlet></router-outlet>
