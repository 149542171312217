import {Component, EventEmitter, Input, OnInit} from "@angular/core";
import {Utils} from "../../utilities/utils";

@Component({
    selector: "app-image-user",
    templateUrl: "./image-user.component.html",
    styleUrls: ["./image-user.component.scss"]
})
export class ImageUserComponent implements OnInit {

    @Input() avatar = null;
    @Input() name: string = "";
    @Input() changeEvent = new EventEmitter();
    @Input() isFeedbackActive: boolean = false;
    @Input() isProfile: boolean = false;
    @Input() isToSelection: boolean = false;
    @Input() isPreview: boolean = false;

    public nickname: string = "";

    public ngOnInit():void {
        if (this.name) {
            this.nickname = Utils.getNickname(this.name);
        }
    }

    public otherImages():boolean{
        return !! !this.isFeedbackActive && !this.isProfile && !this.isPreview;
    }
}
