import {Directive, ElementRef, Host, Input, OnDestroy, OnInit} from "@angular/core";
import {AbstractControlDirective, NgControl} from "@angular/forms";
import {TranslateService} from "../../services/translate.service";
import {MatFormField} from "@angular/material/form-field";
import {Subject} from "rxjs/internal/Subject";
import {takeUntil} from "rxjs/operators";

@Directive({
    selector: "[validator]",
    exportAs: "validator",
})
export class ValidatorDirective implements OnInit, OnDestroy {

    private unsubscribe = new Subject();

    @Input() source: string;
    @Input() target: string;

    public control: NgControl | AbstractControlDirective;

    constructor(public element: ElementRef,
                @Host() public formField: MatFormField,
                public translate: TranslateService) {
    }

    public ngOnInit() {
        setTimeout(() => {
            this.control = this.formField._control.ngControl;
            if (this.control) {
                this.onStatusChange();
                this.onTranslateChange();
                this.updateErrorMessage();
            }
        }, 0);
    }

    public ngOnDestroy() {
        this.unsubscribe.next(undefined);
        this.unsubscribe.complete();
    }

    public onStatusChange(): void {
        this.control.statusChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(value => {
                if (value === "INVALID") {
                    this.updateErrorMessage();
                } else {
                    this.element.nativeElement.textContent = "";
                }
            });
    }

    public onTranslateChange(): void {
        this.translate.onLangChange
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.updateErrorMessage();
            });
    }

    private updateErrorMessage(): void {
        if (this.control.invalid) {
            this.element.nativeElement.textContent = this.errorMessage;
        } else {
            this.element.nativeElement.textContent = "";
        }
    }

    get errorMessage(): string {
        if (this.control && this.control.errors) {
            const key = Object.keys(this.control.errors).pop();
            const replacer = function (t) {
                return "-" + t.toLowerCase();
            };
            const translate = key.replace(/[A-Z]/g, replacer);
            let str = this.translate._(translate);
            if (this.source) {
                str = str.replace("${source}", this.translate._(this.source).toString().toLowerCase());
            }
            if (this.target) {
                str = str.replace("${target}", this.translate._(this.target.toString()).toString().toLowerCase());
            }
            return str;
        }
        return "";
    }
}
