<mat-toolbar class="mat-dialog-header">
    <span class="w-full">{{'filter' | translate}} {{'by' | translate}} {{data.label | translate}}</span>
    <button mat-icon-button [mat-dialog-close]="false">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>
    <form [formGroup]="formGroup" class="flex flex-column gap-3">
        <div class="flex align-items-center gap-2">
            <mat-form-field class="flex-grow-1  ">
                <mat-label>{{data.label | translate}}</mat-label>
                <input [appAutocomplete]="value" formControlName="value" matInput
                       required>
                <app-autocomplete #value
                                  (changeEvent)="getItems($event)"
                                  [display]="data.relationConfiguration.display"
                                  [id]="data.relationConfiguration.pk"
                                  [paginatedResult]="items">
                </app-autocomplete>
                <app-autocomplete-control [for]="value" [limit]="10" matSuffix></app-autocomplete-control>
                <mat-error validator></mat-error>
            </mat-form-field>
            <button mat-raised-button color="accent" class="margin-add" [disabled]="!formGroup.valid" (click)="add()">
                {{ "add" | translate }}
            </button>
        </div>
        <div class="flex-column actions-chip-content gap-2">
            <strong>{{'you-will-be-filtered-by' | translate}}:</strong>
            <mat-chip-grid #chipList aria-label="Item selection">
                @for(filterResult of filterResults; track filterResults) {
                    <mat-chip-row (removed)="remove(filterResult)">
                        {{ filterResult.display }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                }
                <input [matChipInputFor]="chipList"
                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                       [matChipInputAddOnBlur]="addOnBlur"
                       [readonly]="true">
            </mat-chip-grid>
        </div>

    </form>
</mat-dialog-content>

<mat-dialog-actions align="end" class="actions-dialog">
    <button mat-raised-button color="accent" (click)="ok()" [disabled]="!filterResults.length">
        {{'confirm' | translate}}
    </button>
</mat-dialog-actions>
