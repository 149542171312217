import {EventEmitter, Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {Mapping} from "../../../models/levelup/mapping";
import {Candidate} from "../../../models/rh/candidate";

@Injectable()
export class MainService {
    public changeTitle = new Subject<string>();
    public changeSnackBar = new Subject<string>();
    public openSkill = new Subject<void>();
    public showSelectSkillMapping = new Subject<boolean>();
    public currentMapping = new Subject<Mapping>();
    public changeAvatar = new Subject<any>();
    public change_import_baseYear = new EventEmitter<boolean>();
    public change_import_limitHour = new EventEmitter<boolean>();
    public updateRole = new EventEmitter<any>();
    public EditField = new Subject<boolean>();
    public userRecoveryPassword: string;

    constructor(public spinner: NgxUiLoaderService) {
    }
}
