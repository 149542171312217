import {Injectable, OnDestroy} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {shareReplay, tap} from "rxjs/operators";
import {URLS} from "../app/app.urls";
import {User} from "../models/account/user";
import {MainService} from "../components/default/main/main.service";
import {NavigationExtras, Router} from "@angular/router";
import jwtDecode from "jwt-decode";
import {Observable, Subject} from "rxjs";
import {DetailResponse} from "../dto/detail-response";
import {String} from "typescript-string-operations";
import {AppVariables} from "../app/app.variables";
import {environment} from "../../environments/environment";


interface AuthPayload {
    user_id: number;
    username: string;
    user: User;
    exp: number;
    orig_iat: number;
}


@Injectable()
export class AuthService implements OnDestroy {

    private storage = localStorage;
    private readonly urlBase: string;
    private readonly urlUser: string;
    private urlToken: string;
    private urlMenu: string;
    private urlModule: string;
    private urlModuleMenu: string;
    public unsubscribe: Subject<void> = new Subject<void>();


    constructor(public variables: AppVariables,
                public http: HttpClient,
                public mainService: MainService,
                public router: Router) {

        this.urlBase = environment.urlBase;
        this.urlUser = `${this.urlBase}, ${URLS.USER}`;
        this.urlMenu = `${this.urlBase}${URLS.MODULE_MENU}find_menu/`;
        this.urlModule = `${this.urlBase}${URLS.MODULE}`;
        this.urlModuleMenu = `${this.urlBase}${URLS.MODULE_MENU}`;
        this.urlToken = `${this.urlBase}${URLS.ACCESS_TOKEN}`;
    }

    get user(): User {
        if (!this.variables.user) {
            const token = this.storage.getItem("access_token");
            const payload = <AuthPayload>jwtDecode(token);
            const user = payload.user;
            user.url = `${this.urlBase}${user.url}`;
            user.avatar_minio = this.storage.getItem("avatar");
            this.variables.user = user;
        }
        return this.variables.user;
    }

    get token(): string {
        return this.storage.getItem("access_token");
    }

    get avatar(): string {
        return this.storage.getItem("avatar");
    }

    public login(user: User) {
        return this.http.post(this.urlToken, user)
            .pipe(
                tap(response => this.setToken(response)),
                shareReplay(),
            );
    }

    public logout(reload?: boolean, redirect?: boolean, extras?: NavigationExtras): void {
        this.storage.removeItem("access_token");
        this.storage.removeItem("refresh_token");
        this.storage.removeItem("avatar");
        if (reload) {
            location.reload();
        }
        if (redirect) {
            this.router.navigate(["login"], extras).then();
        }
    }

    public isSuperUser(): boolean {
        if (this.user) {
            return this.user.is_superuser;
        }
        return false;
    }

    public isLoggedIn() {
        return !!this.storage.getItem("access_token");
    }


    public changePassword(user: User, data): Observable<DetailResponse> {
        const headers = new HttpHeaders({
            "Authorization": `Bearer ${this.storage.getItem("access_token")}`
        });

        const url = String.Format("{0}{1}/change_password/", this.urlUser, user.id);
        return this.http.patch<DetailResponse>(url, data, {"headers": headers});
    }

    public setToken(authResponse: {}) {
        if (authResponse) {
            Object.keys(authResponse).forEach((key: string) => {
                this.storage.setItem(`${key}_token`, authResponse[key]);
            });
        }
    }


    getRefreshToken() {
        return this.storage.getItem("refresh_token");
    }

    refreshToken(): Observable<any> {
        const payload = {"refresh": this.getRefreshToken()};
        return this.http.post<any>(`${this.urlBase}${URLS.REFRESH_TOKEN}`, payload).pipe(
            tap(response => {
                this.storage.setItem("access_token", response.access);
            })
        );
    }

    public setAvatar(avatar: any) {
        this.storage.setItem("avatar", avatar);
    }

    public removeAvatar() {
        this.storage.removeItem("avatar");
    }

    public changeImage(user: User, data): Observable<User> {
        const headers = new HttpHeaders({
            "Authorization": `Bearer ${this.storage.getItem("access_token")}`
        });

        const url = String.Format("{0}{1}/", this.urlUser, user.id);

        const formData = new FormData();
        Object.keys(data).forEach(key => {
            const value = data[key];
            formData.append(key, value === null || value === undefined ? "" : value);
        });

        return this.http.patch<User>(url, formData, {"headers": headers});
    }


    ngOnDestroy() {
        this.unsubscribe.complete();
    }

}
