import {HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";

export class CommonsService {
    protected parameters: HttpParams = new HttpParams();
    protected urlBase: string;
    protected token: string;

    constructor() {
        this.urlBase = environment.urlBase;
    }

    public clearParameter(): void {
        this.parameters = new HttpParams();
        this.token = null;
    }

    public addParameter(key: string, value: any): void {
        if (this.parameters.has(key)) {
            this.parameters = this.parameters.set(key, value);
        } else {
            this.parameters = this.parameters.append(key, value);
        }
    }

    public getOptions(): object {
        const httpOptions = {};
        if (this.parameters) {
            httpOptions["params"] = this.parameters;
        }
        return httpOptions;
    }

}
