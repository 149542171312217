import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface DialogData {
    title: string;
    message: string;
    description: string;
    button_color: string;
    label_button_no: string;
    label_button_yes: string;
}

@Component({
    selector: "app-dialog",
    templateUrl: "./dialog.component.html",
    styleUrls: ["./dialog.component.scss"]
})
export class DialogComponent implements OnInit {

    public label_button_no = "no";
    public label_button_yes = "yes";
    public default_color = "accent";

    constructor(
        public dialogRef: MatDialogRef<DialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    }

    public cancel(): void {
        this.dialogRef.close();
    }

    ngOnInit(): void {
        if (this.data.label_button_yes) {
            this.label_button_yes = this.data.label_button_yes;
        }
        if (this.data.label_button_no) {
            this.label_button_no = this.data.label_button_no;
        }
        if (this.data.button_color) {
            this.default_color = this.data.button_color;
        }
    }
}
