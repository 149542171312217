<div class="main" [ngClass]="selectedModule?.theme" fpfLayout="column">

    <mat-toolbar class="main-header" fpfFlex="4" fpfLayout="row" fpfLayoutGap="2">
        <div fpfLayout="row" fpfLayoutAlign="center center">
            <button mat-icon-button color="accent" (click)="isExpanded = !isExpanded">
                <mat-icon>menu</mat-icon>
            </button>
            <div fpfLayout="row" fpfLayoutGap="2" fpfLayoutAlign="center start">
                <img alt="" src="assets/images/logo-bit-vert.svg" height="32px">

                <div class="module name">
                    <button mat-button id="main_modules"
                            matTooltip="{{'modules' | translate}}" (click)="openModuleDialog()">
                        <span>{{ this.selectedModule?.description | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
        <div fpfLayout="row" fpfLayoutGap="2" fpfFlex fpfLayoutAlign="center end">
            @if (this.selectedModule?.description === ModuleType.LEVELUP) {
                <strong class="text">{{ "search-by-skill" | translate }}</strong>➡
                <button mat-icon-button (click)="openSearchDialog()">
                    <mat-icon>search</mat-icon>
                </button>
            }
            @if (!user.is_candidate && this.selectedModule?.description === ModuleType.LEVELUP) {
                <button mat-icon-button class="text-white"
                        (click)="openDetailLevelDialog()"
                        matTooltip="{{'skills-levels' | translate}}">
                    <mat-icon>star_rate</mat-icon>
                </button>
            }
            <button mat-button class="text-white" [matMenuTriggerFor]="matMenu" (click)="onChangeAvatar()">
                <mat-icon>person_outline</mat-icon>
                <span class="ml-2">{{ (user?.desired_name ? user?.desired_name : user?.username) | uppercase }}</span>
            </button>
            <mat-menu #matMenu="matMenu">
                <mat-card class="user-card">
                    <mat-card-header>
                        <div mat-card-avatar>
                            <app-image-user [avatar]="user?.avatar_minio"
                                            [name]="user?.name | emptyValue: user?.username"
                                            [changeEvent]="changeEvent">
                            </app-image-user>
                        </div>
                        <mat-card-title>
                            <strong>{{ user?.desired_name ? user?.desired_name : user?.username }}</strong>
                        </mat-card-title>
                        <mat-card-subtitle>{{ user?.name }}</mat-card-subtitle>
                    </mat-card-header>
                </mat-card>
                <mat-divider></mat-divider>
                @if (!authService.user.is_candidate) {
                    <button mat-menu-item (click)="openProfile()">
                        <mat-icon fontSet="fas" fontIcon="fa-user"></mat-icon>
                        <span>{{ 'profile' | translate }}</span>
                    </button>
                }
                @if (!this.user.is_candidate) {
                    <mat-divider></mat-divider>
                    <button mat-menu-item (click)="openCurriculum()">
                        <mat-icon fontSet="fas" fontIcon="fa-address-book"></mat-icon>
                        <span>{{ 'curriculum' | translate }}</span>
                    </button>
                }
                <mat-divider></mat-divider>
                <button mat-menu-item (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>{{ 'sign-out' | translate }}</span>
                </button>
            </mat-menu>
        </div>

    </mat-toolbar>

    <mat-sidenav-container hasBackdrop="false" class="main-container w-screen" fpfFlex="calc(100%-64px)"
                           (window:resize)="onResize($event)">
        <mat-sidenav #sidenav mode="side" [opened]="true" [disableClose]="true" [@sidenavIsExpanded]="isExpanded">
            <app-menu [data]="menuItems" [showLabel]="isExpanded"></app-menu>
            @if (selectedModule?.description === ModuleType.LABS) {
                <div class="custom-mt">
                    <span class="labs-span" fpfLayout
                          fpfLayoutAlign="center center">{{ "join-group-labs" | translate }}</span>
                    <img class="pl-5 pr-5 custom-p" src="assets/images/qr-code-group-labs.svg" alt="">
                </div>
            }
        </mat-sidenav>

        <mat-sidenav-content cdkScrollable fpfLayout="column" fpfLayoutAlign="stretch start"
                             [@sidenavContentIsExpanded]="isExpanded">

            <mat-toolbar class="container-header mat-elevation-z2 w-full">
                <div fpfLayout="row" fpfLayoutAlign="center start" class="w-full">
                    <button mat-icon-button color="primary" (click)="location.back()">
                        <mat-icon>arrow_back</mat-icon>
                    </button>
                    <div><span class="title">{{ title | translate }}</span></div>
                </div>

                @if (showSelectSkillMapping) {
                    <button
                        class="bt-open-filter"
                        fpfLayout="row" fpfLayoutAlign="center" mat-button mat-raised-button color="accent"
                        (click)="openSkill()">
                        <mat-icon>tune</mat-icon>
                        <span>{{ 'areas-of-competence' | translate }}</span>
                    </button>
                }

            </mat-toolbar>

            <div class="container">
                <router-outlet></router-outlet>
            </div>

            <mat-toolbar class="container-footer mat-elevation-z2 w-full" fpfLayout="row">
                <div fpfLayout="row" fpfLayoutAlign="center start">
                    <mat-label>
                        <div class="key-word">
                            <span>{{ 'version-web' | translate }} {{ versionWEB }}</span>
                        </div>
                    </mat-label>
                    <mat-label>
                        <div class="key-word">
                            <span>{{ 'version-api' | translate }} {{ versionAPI }}&emsp;-&emsp;{{ 'all-rights-reserved' | translate }}</span>
                        </div>
                    </mat-label>

                </div>
                <div fpfFlex fpfLayout="row" fpfLayoutAlign="center end"></div>

                    <div fpfLayout="row" fpfLayoutAlign="center center" fpfLayoutGap="2">
                        <span>{{ 'powered-by' | translate }}</span>
                        <a fpfFlex fpfLayout="row"
                           fpfLayoutAlign="center center" fpfLayoutGap="2"
                           href="https://fpftech.com/" target="_blank"
                           class="link-btn">
                            <img alt="Logo" src="assets/images/logo-fpf.svg" height="28px"/>
                        </a>
                    </div>
            </mat-toolbar>

        </mat-sidenav-content>

    </mat-sidenav-container>

</div>
