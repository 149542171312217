import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FilterConfiguration, FilterResult, SelectedFilter} from "../filter-configuration";
import {FormBuilder, FormGroup} from "@angular/forms";
import {CustomValidators} from "../../../utilities/validator/custom-validators";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {CUSTOM_DATE_FORMATS} from "../../../app/app.constant";


@Component({
    selector: "app-filter-date-picker-dialog",
    templateUrl: "./filter-date-picker-dialog.component.html",
    styleUrls: ["./filter-date-picker-dialog.component.scss"],
    providers: [{
        provide: DateAdapter,
        useClass: MomentDateAdapter,
        deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

        {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS},
    ]
})
export class FilterDatePickerDialogComponent implements OnInit {

    public formGroup: FormGroup;

    constructor(@Inject(MAT_DIALOG_DATA) public data: FilterConfiguration,
                public dialogRef: MatDialogRef<any>,
                public formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.createFormGroup();
    }

    private createFormGroup(): void {
        this.formGroup = this.formBuilder.group({
            value: [null, CustomValidators.required]
        });

    }

    public ok(): void {
        const filterResult = new FilterResult();
        filterResult.value = this.formGroup.controls.value.value as Date;

        const selectedFilter = new SelectedFilter();
        Object.assign(selectedFilter, this.data);

        selectedFilter.result = filterResult;

        this.dialogRef.close(selectedFilter);
    }

}
