import {Directive, ElementRef, OnInit} from "@angular/core";

@Directive({
    selector: "[autoFocus]",
    exportAs: "autoFocus"
})
export class AutoFocusDirective implements OnInit {

    constructor(public element: ElementRef) {
    }

    public ngOnInit() {
        setTimeout(() => {
            this.element.nativeElement.focus();
        }, 100);
    }
}
