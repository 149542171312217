<div fpfLayout="column">

    <mat-toolbar fpfLayout class="mat-dialog-header" fpfFlex="100%">
        <span>{{ 'skills-levels' | translate }}</span>
        <div fpfFlex fpfLayout  fpfLayoutAlign="end center"></div>
        <button mat-icon-button [mat-dialog-close]="false">
            <mat-icon>close</mat-icon>
        </button>
    </mat-toolbar>

    <mat-dialog-content>
        <div fpfLayout="column" fpfLayoutGap="2">
            @for (detail of dataSource.data;track detail) {
                <div fpfLayout="row" class="level-item">
                    <div fpfLayout="row" fpfLayoutAlign="center center" fpfFlex="140px">
                        @for (x of createArray(detail.level);track x) {
                            <div>
                                <mat-icon color="accent" class="fas fa-star"></mat-icon>
                            </div>
                        }
                    </div>
                    <div>
                        {{ detail.name | translate }} - {{ detail.description | translate }}
                    </div>
                </div>
            }
        </div>
    </mat-dialog-content>

</div>
