<ng-template ngFor let-d [ngForOf]="data">
    @if (!d.hidden) {
        <mat-nav-list>
            @if (showLabel) {
                <h3 mat-subheader fpfLayout="row" class="m-2">{{ d.label | translate }}</h3>
            }
            <ng-template ngFor let-di [ngForOf]="d.items">
                @if (!di.hidden) {
                    <a mat-list-item
                       [matTooltip]="di.label | translate"
                       [matTooltipShowDelay]="500"
                       [routerLink]="[di?.route]"
                       [routerLinkActive]="['active']"
                       [routerLinkActiveOptions]="{exact: false}" fpfLayout="row" >
                        @if (di?.icon) {
                            <mat-icon matListItemIcon class="text-white" fontSet="fas" [fontIcon]="di?.icon"></mat-icon>
                        }
                        <span class="text-white" matLine>{{ di.label | translate }}</span>
                    </a>
                }

                @if (di.divider) {
                    <mat-divider></mat-divider>
                }
            </ng-template>
        </mat-nav-list>
    }

</ng-template>
