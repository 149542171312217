import {Injectable} from "@angular/core";
import * as CryptoJS from "crypto-js";

@Injectable()
export class AESEncryptDecryptService {

    private secretKey = "1234567890";
    constructor() {}

    public encrypt(value : string) : string {
        return CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString();
    }

    public decrypt(textToDecrypt : string) : string {
        return CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(CryptoJS.enc.Utf8);
    }
}
