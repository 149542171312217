import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface DialogData {
    title: string;
    message: string;
    description: string;
    confirmationButton?: string;
    cancel?: string;
}

@Component({
    selector: "app-dialog",
    templateUrl: "./delete-confirm-dialog.component.html",
    styleUrls: ["./delete-confirm-dialog.component.scss"],
})
export class DeleteConfirmDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<DeleteConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {}

    public close(confirm: boolean): void {
        this.dialogRef.close(confirm);
    }
}
