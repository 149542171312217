<div [fpfFill]="true" class="forgot" fpfLayout="column" fpfLayoutAlign="center center" fpfLayoutGap="4">

    <form [formGroup]="formGroup">
        <mat-card fpfFlex="400px">
            <div mat-card-image fpfLayoutAlign="center center" fpfLayout="column">
                <div>
                    <img src="../../../../assets/images/bit-initial.svg" height="85px" style="margin-bottom: 10px"/>
                </div>
                <div>
                    <img src="assets/images/logo-fpf.svg" height="28px" class="logo-fpf" alt="FPF-Tech">
                </div>
            </div>

            @if (!showSecondComponent) {
                <div fpfLayout="column">
                    <mat-card-content fpfLayout fpfLayoutAlign="center evenly" fpfLayoutGap="1">
                        <div fpfLayout="column" fpfFlex="90%" fpfLayoutGap="1">
                            <strong>{{ 'info-send-code'| translate }}</strong>

                            <mat-form-field>
                                <mat-label>{{ 'username'| translate }}</mat-label>
                                <input matInput type="text" maxlength="64" formControlName="username" required autoFocus
                                       autocomplete="off">
                                <mat-error validator>{{ 'the-field-is-mandatory' | translate }}</mat-error>
                            </mat-form-field>
                        </div>
                    </mat-card-content>


                    <mat-card-actions fpfLayout fpfLayoutAlign="center evenly" fpfLayoutGap="1">
                        <div fpfLayout="row" fpfLayoutGap="1" class="box-align">
                            <button mat-button mat-stroked-button class="cancel" type="button"
                                    (click)="goToPage('login')">
                                {{ "cancel" | translate }}
                            </button>
                            <button type="submit" class="submit" mat-button
                                    [disabled]="!formGroup.valid"
                                    (click)="sendCode()">
                                {{ 'send' | translate }}
                            </button>
                        </div>
                    </mat-card-actions>
                </div>
            }
            @if (showSecondComponent) {
                <div>
                    <app-confirm-code></app-confirm-code>
                </div>
            }
        </mat-card>
    </form>
</div>
