import {CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule, IMAGE_CONFIG, NgOptimizedImage} from "@angular/common";
import {MatCardModule} from "@angular/material/card";
import {MatMenuModule} from "@angular/material/menu";
import {MatDatepickerIntl, MatDatepickerModule} from "@angular/material/datepicker";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatChipsModule} from "@angular/material/chips";
import {MatNativeDateModule} from "@angular/material/core";
import {MatDividerModule} from "@angular/material/divider";
import {MatListModule} from "@angular/material/list";
import {MatRadioModule} from "@angular/material/radio";
import {MatSliderModule} from "@angular/material/slider";
import {MatSortModule} from "@angular/material/sort";
import {MatStepperModule} from "@angular/material/stepper";
import {MatToolbarModule} from "@angular/material/toolbar";
import {AuthService} from "./services/auth.service";
import {MatDialogModule} from "@angular/material/dialog";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatSelectModule} from "@angular/material/select";
import {MatTableModule} from "@angular/material/table";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatTabsModule} from "@angular/material/tabs";
import {DialogComponent} from "./shared/dialog/dialog.component";
import {ValidatorDirective} from "./utilities/validator/validator.directive";
import {BlockUiComponent} from "./shared/block-ui/block-ui.component";
import {TranslateModule} from "@ngx-translate/core";
import {AutocompleteModule} from "./shared/autocomplete/autocomplete.module";
import {AutoFocusDirective} from "./utilities/auto-focus.directive";
import {DictToArrayPipe} from "./utilities/dict-to-iterable.pipe";
import {ChoicesPipe} from "./utilities/choices.pipe";
import {LocalDatePipe} from "./utilities/local-date.pipe";
import {DateFormatDirective} from "./utilities/date-format.directive";
import {LocalCurrencyPipe} from "./utilities/local-currency.pipe";
import {UppercaseDirective} from "./utilities/uppercase.directive";
import {EmptyValuePipe} from "./utilities/empty-value.pipe";
import {DashboardUiComponent} from "./shared/dashboard-ui/dashboard-ui.component";
import {HistoryComponent} from "./shared/history/history.component";
import {NgPipesModule} from "ngx-pipes";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {ListImageDirective} from "./utilities/list-image.directive";
import {ImageUserComponent} from "./shared/image-user/image-user.component";
import {ChoicesDirective} from "./utilities/choices.directive";
import {DualListComponent} from "./shared/dual-list/dual-list.component";
import {DualListPipe} from "./shared/dual-list/dual-list.pipe";
import {ProgressComponent} from "./shared/progress/progress.component";
import {NgxStarsModule} from "ngx-stars";
import {BoldSearchPipe} from "./utilities/bold.pipe";
import {CpfPipe} from "./utilities/cpf.pipe";
import {CepPipe} from "./utilities/cpe.pipe";
import {CellphoneFormatPipePipe} from "./utilities/cellphone-formate.pipe";
import {AdvancedFilterComponent} from "./shared/layout/advanced-filter/advanced-filter.component";
import {
    AutoCompleteInfiniteScrollComponent,
} from "./shared/layout/autocomplete-infinite-scroll/autocomplete-infinite-scroll.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {LowercaseDirective} from "./utilities/lowercase.directive";
import {HistoryNoModalComponent} from "./shared/history-no-modal/history-no-modal.component";
import {RecordHistoryComponent} from "./shared/record-history/record-history.component";
import {MatPaginatorModule} from "@angular/material/paginator";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {NgxEchartsModule} from "ngx-echarts";
import {MatFormFieldModule} from "@angular/material/form-field";
import {LayoutDirective} from "./utilities/fpf-layout/layout.directive";
import {FlexDirective} from "./utilities/fpf-layout/flex.directive";
import {FlexLayoutDirective} from "./utilities/fpf-layout/flex-layout.directive";
import {FlexWrapResponsiveDirective} from "./utilities/fpf-layout/fpf-layout-responsive/flex-wrap-responsive.directive";
import {
    FlexLayoutAlignResponsiveDirective
} from "./utilities/fpf-layout/fpf-layout-responsive/flex-layout-align-responsive.directive";
import {
    FlexLayoutGapResponsiveDirective
} from "./utilities/fpf-layout/fpf-layout-responsive/flex-layout-gap-responsive.directive";
import {
    FlexLayoutResponsiveDirective
} from "./utilities/fpf-layout/fpf-layout-responsive/flex-layout-responsive.directive";
import {FilterComponent} from "./shared/filter/filter.component";
import {FilterInputDialogComponent} from "./shared/filter/filter-input-dialog/filter-input-dialog.component";
import {
    FilterInputRangeDialogComponent
} from "./shared/filter/filter-input-range-dialog/filter-input-range-dialog.component";
import {
    FilterInputMultipleDialogComponent
} from "./shared/filter/filter-input-multiple-dialog/filter-input-multiple-dialog.component";
import {
    FilterDatePickerDialogComponent
} from "./shared/filter/filter-datepicker-dialog/filter-date-picker-dialog.component";
import {
    FilterDatePickerRangeDialogComponent
} from "./shared/filter/filter-date-picker-range-dialog/filter-date-picker-range-dialog.component";
import {FilterRelationDialogComponent} from "./shared/filter/filter-relation-dialog/filter-relation-dialog.component";
import {
    FilterRelationMultipleDialogComponent
} from "./shared/filter/filter-relation-multiple-dialog/filter-relation-multiple-dialog.component";
import {FilterChoiceDialogComponent} from "./shared/filter/filter-choice-dialog/filter-choice-dialog.component";
import {
    FilterChoiceMultipleDialogComponent
} from "./shared/filter/filter-choice-multiple-dialog/filter-choice-multiple-dialog.component";
import {NgxMaskDirective} from "ngx-mask";
import {RemoveHtmlTagsPipe} from "./utilities/strip-html.pipe";
import {RemoveNbspTextPipe} from "./utilities/&nbsp-strip.pipe";
import {ChangeTooLong} from "./utilities/too-long.pipe";

@NgModule({
    declarations: [
        AutoFocusDirective,
        ValidatorDirective,
        DateFormatDirective,
        UppercaseDirective,
        LowercaseDirective,
        ListImageDirective,
        DictToArrayPipe,
        EmptyValuePipe,
        ChoicesPipe,
        LocalDatePipe,
        LocalCurrencyPipe,
        DualListPipe,
        BlockUiComponent,
        DashboardUiComponent,
        DialogComponent,
        DualListComponent,
        HistoryComponent,
        ImageUserComponent,
        ChoicesDirective,
        ProgressComponent,
        BoldSearchPipe,
        CpfPipe,
        CepPipe,
        CellphoneFormatPipePipe,
        AdvancedFilterComponent,
        AutoCompleteInfiniteScrollComponent,
        HistoryNoModalComponent,
        RecordHistoryComponent,
        FilterComponent,
        FilterInputDialogComponent,
        FilterInputRangeDialogComponent,
        FilterInputMultipleDialogComponent,
        FilterDatePickerDialogComponent,
        FilterDatePickerRangeDialogComponent,
        FilterRelationDialogComponent,
        FilterRelationMultipleDialogComponent,
        FilterChoiceDialogComponent,
        FilterChoiceMultipleDialogComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        AutocompleteModule,
        NgPipesModule,
        NgxEchartsModule,
        MatListModule,
        MatFormFieldModule,
        MatChipsModule,
        MatInputModule,
        MatCardModule,
        LayoutDirective,
        FlexDirective,
        FlexLayoutDirective,
        FlexWrapResponsiveDirective,
        FlexLayoutAlignResponsiveDirective,
        FlexLayoutGapResponsiveDirective,
        FlexLayoutResponsiveDirective,
        MatButtonModule,
        MatIconModule,
        MatSelectModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatDialogModule,
        MatExpansionModule,
        MatMenuModule,
        MatTabsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatDividerModule,
        MatToolbarModule,
        MatSliderModule,
        MatStepperModule,
        MatRadioModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        DragDropModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        NgOptimizedImage,
        NgxMaskDirective,
        RemoveHtmlTagsPipe,
        RemoveNbspTextPipe,
        ChangeTooLong
    ],
    exports: [
        AutoFocusDirective,
        ValidatorDirective,
        DateFormatDirective,
        UppercaseDirective,
        LowercaseDirective,
        ListImageDirective,
        DictToArrayPipe,
        EmptyValuePipe,
        ChoicesPipe,
        LocalDatePipe,
        LocalCurrencyPipe,
        DualListPipe,
        BlockUiComponent,
        ImageUserComponent,
        DashboardUiComponent,
        DialogComponent,
        HistoryComponent,
        DualListComponent,
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        AutocompleteModule,
        // NgPipesModule,
        MatListModule,
        MatInputModule,
        MatCardModule,
        MatButtonModule,
        MatIconModule,
        MatSelectModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatDialogModule,
        MatExpansionModule,
        NgxEchartsModule,
        MatMenuModule,
        MatTabsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatDividerModule,
        MatToolbarModule,
        MatChipsModule,
        MatSliderModule,
        MatStepperModule,
        MatRadioModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        DragDropModule,
        ChoicesDirective,
        ProgressComponent,
        // FileUploadModule,
        BoldSearchPipe,
        CpfPipe,
        CepPipe,
        CellphoneFormatPipePipe,
        AdvancedFilterComponent,
        HistoryComponent,
        HistoryNoModalComponent,
        RecordHistoryComponent,
        FilterComponent,

        // NgxMatDatetimePickerModule,
        // NgxMatTimepickerModule,
        // NgxMatMomentModule,
    ],
    providers: [
        NgxStarsModule,
        InfiniteScrollModule, {
            provide: IMAGE_CONFIG,
            useValue: {
                breakpoints: [16, 48, 96, 128, 384, 640, 750, 828, 1080, 1200, 1920]
            }
        },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                AuthService,
                // AppGuard,
                MatDatepickerIntl,
            ]
        };
    }
}
