import {Component, Inject, Injector} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AuthService} from "../../../services/auth.service";
import {URLS} from "../../../app/app.urls";
import {BaseComponentDirective, BaseOptions} from "../../base-component.directive";
import {User} from "../../../models/account/user";

const BASE_OPTIONS: BaseOptions = {
    pk: "id",
    endpoint: URLS.USER
};

@Component({
    selector: "app-lgpd-term-dialog",
    templateUrl: "./lgpd-term-dialog.component.html",
    styleUrls: ["./lgpd-term-dialog.component.scss"]
})
export class LgpdTermDialogComponent extends BaseComponentDirective<User> {

    constructor(public injector: Injector,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public authService: AuthService,
                public dialogRef: MatDialogRef<LgpdTermDialogComponent>) {
        super(injector, BASE_OPTIONS);
    }
    public createFormGroup(): void {
        this.formGroup = this.formBuilder.group({
            name:this.data.name,
            email:this.data.email

        });
    }



}
