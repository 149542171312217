import {Component, Injector} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {URLS} from "../../../../app/app.urls";
import {CustomValidators} from "../../../../utilities/validator/custom-validators";
import {takeUntil} from "rxjs/operators";
import {Code} from "../../../../models/account/code";
import {MainService} from "../../main/main.service";
import {BaseComponentDetailDirective, BaseDetailComponentOptions} from "../../../base-component-detail.directive";

const BASE_OPTIONS: BaseDetailComponentOptions = {
    endpoint: URLS.CODE,
    retrieveOnInit: true
};

@Component({
    selector: "app-confirm-code",
    templateUrl: "./confirm-code.component.html",
    styleUrls: ["./confirm-code.component.scss"]
})
export class ConfirmCodeComponent extends BaseComponentDetailDirective<Code> {
    public formGroup: UntypedFormGroup;

    constructor(
        public mainService: MainService,
        public formBuilder: UntypedFormBuilder,
        public injector: Injector,
    ) {
        super(injector, BASE_OPTIONS);
    }

    public createFormGroup() {
        this.formGroup = this.formBuilder.group({
            code: [null, CustomValidators.required],
        });
    }

    public verifyCode() {
        this.service.clearParameter();
        const payload = {
            code: this.v.code,

        };
        this.service.postFromListRoute("confirm_code", payload)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.toast.success("verify", "code-verify-successfully");
                this.goToPage("new-password");
            });
    }

    public rSendCode() {
        this.service.clearParameter();
        const payload = {
            username: this.mainService.userRecoveryPassword,
        };
        this.service.postFromListRoute("send_code", payload)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.toast.success("user", "email-sent-successfully");
            });
    }

}
