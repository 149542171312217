import {Directive, InjectionToken, Injector, OnDestroy, OnInit, ViewChild} from "@angular/core";

import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs/internal/Observable";

import {take, takeWhile} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";

import {NavigationExtras, Router} from "@angular/router";
import {MatTabGroup} from "@angular/material/tabs";
import {Utils} from "../utilities/utils";
import {AutoFocusDirective} from "../utilities/auto-focus.directive";
import {MainService} from "./default/main/main.service";
import {TranslateService} from "../services/translate.service";
import {ToastService} from "../services/toast.service";
import {BaseService} from "../services/base.service";
import {Subject} from "rxjs";
import {DialogComponent} from "../shared/dialog/dialog.component";
import {HistoryComponent} from "../shared/history/history.component";

export interface BaseOptionsCommons {
    pk?: string;
    endpoint: string;
    paramsOnInit?: {};
    formTitle?: string;
    retrieveIdRoute?: string;
    formRoute? : string
}

export const handler = (event: number, callback?: (event: number) => void) => {
    if (callback) {
        callback(event);
    }
};

export const csvFileName = (endpoint: string, extension: string = "csv") => {
    let filename = `${Utils.nowStr("DDMMYYYY_HHmmss")}.` + extension;
    try {
        const split = endpoint.split("/");
        const model = split[split.length - 2];
        filename = model.concat(filename);
    } catch (e) {
    }
    return filename;
};

@Directive()
export class BaseComponentCommons<T> implements OnDestroy, OnInit{
    @ViewChild(MatTabGroup) public tabGroup: MatTabGroup;
    @ViewChild(AutoFocusDirective) public autoFocus: AutoFocusDirective;
    public http: HttpClient;
    public dialog: MatDialog;
    public main: MainService;
    public toast: ToastService;
    public translate: TranslateService;
    public router: Router;
    public object: T | {};
    public pk: string;
    public service: BaseService<T>;
    public unsubscribe = new Subject();


    constructor(
        public injector: Injector,
        public options: BaseOptionsCommons,
    ) {
        this.http = this.injector.get(HttpClient);
        this.dialog = this.injector.get(MatDialog);
        this.main = injector.get(MainService);
        this.toast = injector.get(ToastService);
        this.translate = injector.get(TranslateService);
        this.router = injector.get(Router);
        this.service = injector.get(this.serviceToken());
    }

    ngOnInit() {
        if (this.options.formTitle) {
            this.changeTitlePage(this.options.formTitle);
        }
    }

    public serviceToken(): InjectionToken<BaseService<T>> {
        return new InjectionToken<BaseService<T>>("service_" + this.options.endpoint, {
            providedIn: "root", factory: () => new BaseService<T>(this.http, this.options.endpoint),
        });
    }

    public confirm(message?: string, subtitle?: string, wait: boolean = false): Observable<boolean> {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: "350px",
            data: {
                title: this.translate._("confirm"),
                message: subtitle || this.translate._("action-confirm"),
                description: message,
            }
        });

        return dialogRef.afterClosed()
            .pipe(take(1), takeWhile(value => !!value || wait));
    }

    public history(pk = null, ...exclude: string[]): void {
        this.dialog.open(HistoryComponent, {
            width: "60%",
            minHeight: "60%",
            maxHeight: "80%",
            data: {
                pk: pk ? pk : this.object[this.pk],
                service: this.service,
                exclude: exclude,
            },
        });
    }

    public goToPage(route: string): void {
        const extras: NavigationExtras = { queryParamsHandling: "merge" };
        this.router.navigate([route], extras).then();
    }

    public goToTab(index: number): void {
        if (this.tabGroup) {
            this.tabGroup.selectedIndex = index;
        }
    }

    public requestFocus(): void {
        if (this.autoFocus) {
            setTimeout(() => {
                this.autoFocus.element.nativeElement.focus();
            }, 200);
        }
    }

    public changeTitlePage(title?: string) {
        this.main.changeTitle.next(title);
    }

    // Create model base service
    public createService<K>(model: new () => K, path: string): BaseService<K> {
        const TOKEN = new InjectionToken<BaseService<K>>("service_" + path, {
            providedIn: "root",
            factory: () => new BaseService<K>(this.http, path),
        });
        return this.injector.get(TOKEN);
    }

    get formRoute(): string {
        return this.options.formRoute ?? "";
    }

    ngOnDestroy(): void {
        this.unsubscribe.next({});
        this.unsubscribe.complete();
    }
}
