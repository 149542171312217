import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FieldType, FilterConfiguration, FilterResult, NumberRange, SelectedFilter} from "../filter-configuration";
import {FormBuilder, FormGroup} from "@angular/forms";
import {CustomValidators} from "../../../utilities/validator/custom-validators";


@Component({
    selector: "app-filter-input-range-dialog",
    templateUrl: "./filter-input-range-dialog.component.html",
    styleUrls: ["./filter-input-range-dialog.component.scss"]
})
export class FilterInputRangeDialogComponent implements OnInit {

    public formGroup: FormGroup;

    constructor(@Inject(MAT_DIALOG_DATA) public data: FilterConfiguration,
                public dialogRef: MatDialogRef<any>,
                public formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.createFormGroup();
    }

    private createFormGroup(): void {
        if(this.data.fieldType == FieldType.NUMBER) {
            this.formGroup = this.formBuilder.group({
                start: [null, CustomValidators.required],
                end: [null, [CustomValidators.required, CustomValidators.nonLt("start")]]
            });
        }else if(this.data.fieldType == FieldType.STRING){
            this.formGroup = this.formBuilder.group({
                start: [null, CustomValidators.required],
                end: [null, [CustomValidators.required]]
            });
        }

    }

    public ok(): void {
        const filterResult = new FilterResult();

        const value = new NumberRange();
        if(this.data.fieldType == FieldType.NUMBER){
            value.start = Number(this.formGroup.controls.start.value);
            value.end = Number(this.formGroup.controls.end.value);
        } else if(this.data.fieldType == FieldType.STRING){

            let yearStart = this.formGroup.controls.start.value.substring(3, 7);
            let monthStart = this.formGroup.controls.start.value.substring(0, 2);
            value.start = Number(yearStart.concat(monthStart));

            let yearEnd = this.formGroup.controls.end.value.substring(3, 7);
            let monthEnd = this.formGroup.controls.end.value.substring(0, 2);

            value.end = Number(yearEnd.concat(monthEnd));
        }

        filterResult.value = value;

        const selectedFilter = new SelectedFilter();
        Object.assign(selectedFilter, this.data);

        selectedFilter.result = filterResult;

        this.dialogRef.close(selectedFilter);
    }

    protected readonly FieldType = FieldType;
}
