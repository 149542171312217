import {Component, Injector, OnInit} from "@angular/core";
import {MainService} from "../../default/main/main.service";
import {URLS} from "../../../app/app.urls";
import {MappingLevelDetail} from "../../../models/levelup/mapping-level-detail";
import {BaseComponentListDirective, BaseListComponentOptions} from "../../base-component-list.directive";

const BASE_OPTIONS: BaseListComponentOptions = {
    endpoint: URLS.MAPPING_LEVEL_DETAIL,
    searchOnInit: true
};

@Component({
    selector: "app-detail-level-dialog",
    templateUrl: "./detail-level-dialog.component.html",
    styleUrls: ["./detail-level-dialog.component.scss"]
})
export class DetailLevelDialogComponent extends BaseComponentListDirective<MappingLevelDetail> implements OnInit {

    constructor(public mainService: MainService,
                public injector: Injector) {
        super(injector, BASE_OPTIONS);
    }

    public createArray(counter: number): number[] {
        return Array.from(new Array(counter), (val, index) => index + 1);
    }

}
