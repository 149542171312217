<mat-toolbar class="mat-dialog-header">
    <span class="w-full">{{'filter' | translate}} {{'by' | translate}} {{data.label | translate}}</span>
    <button mat-icon-button [mat-dialog-close]="false">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>

<mat-dialog-content>
    <form [formGroup]="formGroup" class="flex flex-column">
        <mat-form-field>
            <mat-label>{{data.label | translate}}</mat-label>
            <mat-select formControlName="value" required (choicesEvent)="getChoices()">
                <mat-option *ngFor="let choice of choiceList"
                            [value]="choice.value">{{choice.display_name}}
                </mat-option>
            </mat-select>
            <mat-error validator></mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end" class="actions-dialog">
    <button mat-raised-button color="accent" (click)="ok()" [disabled]="!formGroup.valid">
        {{'confirm' | translate}}
    </button>
</mat-dialog-actions>
