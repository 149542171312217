import {SwiperConfigInterface} from "ngx-swiper-wrapper";
import {NgxUiLoaderConfig} from "ngx-ui-loader";
import {DateAdapter, MAT_DATE_FORMATS} from "@angular/material/core";
import {CustomDateAdapter} from "../utilities/custom-date-adapter";
import {CurrencyMaskConfig} from "ng2-currency-mask";
import {TranslateLoader} from "@ngx-translate/core";
import {WebpackTranslateLoader} from "../shared/translates/webpack-translate-loader";

export const LOADER_OPTIONS: NgxUiLoaderConfig = {
    "bgsColor": "#ff5722",
    "bgsOpacity": 0.5,
    "bgsPosition": "bottom-right",
    "bgsSize": 80,
    "bgsType": "three-bounce",
    "blur": 5,
    "fgsColor": "#ff5722",
    "fgsPosition": "center-center",
    "fgsSize": 80,
    "fgsType": "three-bounce",
    "gap": 24,
    "logoPosition": "center-center",
    "logoSize": 120,
    "logoUrl": "",
    // "masterLoaderId": "master",
    "overlayBorderRadius": "0",
    "overlayColor": "rgba(40, 40, 40, 0.8)",
    "pbColor": "#ff5722",
    "pbDirection": "ltr",
    "pbThickness": 3,
    "hasProgressBar": true,
    "text": "",
    "textColor": "#FFFFFF",
    "textPosition": "center-center",
    // "threshold": 500
};

export const TOAST_OPTIONS = {
    positionClass: "toast-top-right",
    timeOut: 5000,
    progressBar: true,
    tapToDismiss: true,
    preventDuplicates: true,
};

export const CUSTOM_DATE_FORMATS = {
    parse: {
        dateInput: "L",
    },
    display: {
        dateInput: "L",
        monthYearLabel: "MMM YYYY",
        dateA11yLabel: "LL",
        monthYearA11yLabel: "MMMM YYYY",
    },
};

export const CUSTOM_DATE_ADAPTER = [
    {provide: DateAdapter, useClass: CustomDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS},
];


export const CUSTOM_DATE_CURRICULUM = {
    parse: {
        dateInput: "MM/YYYY",
    },
    display: {
        dateInput: "MM/YYYY",
        monthYearLabel: "MMM YYYY",
        dateA11yLabel: "LL",
        monthYearA11yLabel: "MMMM YYYY",
    },
};


export const CUSTOM_DATE_CURRICULUM_ADAPTER = [
    {provide: DateAdapter, useClass: CustomDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_CURRICULUM},
];

export const EN_CURRENCY_MASK_CONFIG: CurrencyMaskConfig = {
    align: "right",
    allowNegative: true,
    decimal: ".",
    precision: 2,
    prefix: "$ ",
    suffix: "",
    thousands: ","
};

export const PT_CURRENCY_MASK_CONFIG: CurrencyMaskConfig = {
    align: "right",
    allowNegative: true,
    decimal: ",",
    precision: 2,
    prefix: "R$ ",
    suffix: "",
    thousands: "."
};

export const CUSTOM_MASKS = {
    DATE_BR: [/[0-3]/, /\d/, "/", /[0-1]/, /[0-9]/, "/", /[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
    DATE_EN: [/[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/, "/", /[0-1]/, /[0-9]/, "/", /[0-3]/, /\d/],
    TIME: [/[0-2]/, /\d/, ":", /[0-5]/, /[0-9]/, ":", /[0-5]/, /[0-9]/],
    NUMBER: [/\d/]
};

export const APP_SWIPER_CONFIG: SwiperConfigInterface = {
    direction: "horizontal",
    slidesPerView: 1,
    spaceBetween: 20,
    loop: false,
    speed: 2000,
    loopAdditionalSlides: 1,
    observer: true,
    autoplay: {
        delay: 30000,
        waitForTransition: true
    }
};

export const STATUS_CHOICES: any[] = [
    {value: 1, viewValue: "Completed"},
    {value: 2, viewValue: "Ongoing"},
    {value: 3, viewValue: "Interrupted"},
];

export const TRANSLATE_CONFIG = {
    loader: {provide: TranslateLoader, useClass: WebpackTranslateLoader}
};

