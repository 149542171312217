<div class="flex gap-2 mt-4">
    <mat-form-field id="filter" class="w-full remove-form-subscript-wrapper">
        <mat-label>{{ 'filters' | translate }}</mat-label>
        <mat-chip-grid #chipList>
            @for (selectedFilter of selectedFilters; track selectedFilter) {
                <mat-chip-row (removed)="remove(selectedFilter)" (dblclick)="editFilter(selectedFilter)"
                              class="ellipsis">
                    @if ((selectedFilter.fieldType === 'text' || selectedFilter.fieldType === 'number') && selectedFilter.filterType === 'normal') {
                        <div>
                            <strong>{{ selectedFilter.label | translate }}: </strong>&nbsp;
                            {{ selectedFilter.result['value'] }}
                        </div>
                    } @else if ((selectedFilter.fieldType === 'text' || selectedFilter.fieldType === 'number') && selectedFilter.filterType === 'range') {
                        <div>
                            <strong>{{ selectedFilter.label | translate }}: </strong>&nbsp;
                            {{ selectedFilter.result['value']?.start }} {{ 'between' | translate }} {{ selectedFilter.result['value']?.end }}
                        </div>
                    } @else if ((selectedFilter.fieldType === 'text' || selectedFilter.fieldType === 'number') && selectedFilter.filterType === 'multiple') {
                        <div>
                            <strong>{{ selectedFilter.label | translate }}: </strong>&nbsp;
                            {{ getValueInputMultiple(selectedFilter) }}
                        </div>
                    } @else if (selectedFilter.fieldType === 'date' && selectedFilter.filterType === 'normal') {
                        <div>
                            <strong>{{ selectedFilter.label | translate }}: </strong>&nbsp;
                            {{ selectedFilter.result['value'] | localDate: 'shortDate' }}
                        </div>
                    } @else if (selectedFilter.fieldType === 'date' && selectedFilter.filterType === 'range') {
                        <div>
                            <strong>{{ selectedFilter.label | translate }}: </strong>&nbsp;
                            {{ selectedFilter.result['value']['start'] | localDate: 'shortDate' }} {{ 'between' | translate }} {{ selectedFilter.result['value']['end'] | localDate: 'shortDate' }}
                        </div>
                    } @else if (selectedFilter.fieldType === 'boolean') {

                        <ng-container>
                            <strong>{{ selectedFilter.label | translate }}: </strong>&nbsp;
                            @if (selectedFilter.result['value'] === true) {
                                <ng-container>{{ selectedFilter.labelsBoolean.get(true) | translate }}</ng-container>
                            } @else {
                                <ng-container>{{ selectedFilter.labelsBoolean.get(false) | translate }}</ng-container>
                            }
                        </ng-container>
                    } @else if ((selectedFilter.fieldType === 'choice' || selectedFilter.fieldType === 'relation') && selectedFilter.filterType === 'multiple') {
                        <div>
                            <strong>{{ selectedFilter.label | translate }}</strong>&nbsp;:
                            <ng-container>{{ getValueChoiceOrRelationMultiple(selectedFilter) }}</ng-container>
                        </div>
                    } @else if ((selectedFilter.fieldType === 'relation' || selectedFilter.fieldType === 'choice') && selectedFilter.filterType === 'normal') {
                        <div>
                            <strong>{{ selectedFilter.label | translate }}</strong>&nbsp;:
                            <ng-container>{{ selectedFilter.result['display'] }}</ng-container>
                        </div>
                    }

                    <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip-row>
            }

            <mat-chip-row [matMenuTriggerFor]="menu" class="bt-add-filter">
                {{ 'add-filter' | translate }}
                <mat-menu #menu="matMenu">
                    @for (conf of configurations; track conf) {
                        @if (conf.fieldType !== FieldType.BOOLEAN) {
                            <button (click)="add(conf)" mat-menu-item [value]="conf.name">
                                {{ conf.label | translate }}
                            </button>
                        } @else {
                            <button mat-menu-item [value]="conf.name" [matMenuTriggerFor]="menu">
                                {{ conf.label | translate }}

                                <mat-menu #menu=matMenu>

                                    <mat-radio-group fpfFlex fpfFlexLayout="column"
                                                     (change)="addBooleanFilter(conf, $event.value)"
                                                     [ngModel]="filterBooleanControls.get(conf.name)">
                                        <mat-radio-button
                                            [value]="true" (click)="$event.stopPropagation()">
                                                <span class="cursor-pointer">
                                                    {{ conf.labelsBoolean.get(true) | translate }}
                                                </span>
                                        </mat-radio-button>

                                        <mat-radio-button
                                            [value]="false" (click)="$event.stopPropagation()">
                                                <span class="cursor-pointer">
                                                    {{ conf.labelsBoolean.get(false) | translate }}
                                                </span>
                                        </mat-radio-button>

                                    </mat-radio-group>

                                </mat-menu>

                            </button>
                        }
                    }
                </mat-menu>
            </mat-chip-row>
            <input [matChipInputFor]="chipList"
                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                   [matChipInputAddOnBlur]="addOnBlur" readonly>
        </mat-chip-grid>
    </mat-form-field>
    <button id="clear-filter" mat-stroked-button class="w-10rem h-button" [disabled]="selectedFilters.length === 0" type="button"
            color="accent"
            (click)="clearFilters()">{{ "clear-filter" | translate }}
    </button>
</div>
