import {Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Renderer2} from "@angular/core";
import {Utils} from "./utils";
import {Subject} from "rxjs/internal/Subject";

@Directive({
    selector: "img[listImage]",
    exportAs: "listImage",
})
export class ListImageDirective implements OnInit, OnDestroy {

    @Input() avatar: string;
    @Input() changeEvent = new EventEmitter();
    private unsubscribe = new Subject();


    constructor(private el: ElementRef<HTMLDivElement>,
                private renderer: Renderer2) {
    }

    public ngOnInit(): void {
        this.setImage();
        this.changeEvent.subscribe(avatar => {
            this.avatar = avatar;
            this.setImage();
        });
    }

    public ngOnDestroy() {
        this.unsubscribe.next(undefined);
        this.changeEvent.complete();
    }

    public setImage(): void {
        if (this.avatar) {
            this.renderer.setProperty(this.el.nativeElement, "src", Utils.convertBase64ToImage(this.avatar));
        }
    }

}
