import {ModelBase} from "../model-base";
import {User} from "../account/user";
import {FunctionModel} from "../levelup/function";
import {Candidate} from "./candidate";
import {RoleType} from "../levelup/role-type";
import {ProfessionalExperience} from "./professional-experience";

export class Vacancy extends ModelBase {
    function: FunctionModel | string;
    quantity: number;
    client: string;
    project: string;
    user: User;
    description: string;
    status_vacancy: string;
    role_level: string | RoleType;
    role_type: string | RoleType;
    job_modality: string;
    job_type: string;
    salary: number;
    benefits: string;
    expiration_date: Date;
    candidates: string[] | Candidate[];
    display_job_modality: string;
}
