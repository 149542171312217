import {Component, Inject, Injector, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ReCaptchaV3Service} from "ng-recaptcha";

@Component({
    selector: "app-profile-badge-item",
    templateUrl: "./profile-badge-item.component.html",
    styleUrls: ["./profile-badge-item.component.scss"]
})
export class ProfileBadgeItemComponent implements OnInit{


    constructor(
        public injector: Injector,
        public dialogRef: MatDialogRef<ProfileBadgeItemComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private recaptchaV3Service: ReCaptchaV3Service
    ) {
    }

    ngOnInit(): void {
        this.executeImportantAction();
    }

    createFormGroup(): void {
    }

    public executeImportantAction(): void {
        this.recaptchaV3Service.execute("importantAction")
            .subscribe((token) => this.handleToken(token));
    }

    private handleToken(token: string): void {
        if (token === "valid") {
            // console.log(token);
        } else {
            // console.log(token);
        }
    }

    public resolved() {
        this.dialogRef.close();
    }

}
