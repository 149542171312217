import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FilterConfiguration, FilterResult, SelectedFilter} from "../filter-configuration";
import {FormBuilder, FormGroup} from "@angular/forms";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {CustomValidators} from "../../../utilities/validator/custom-validators";


@Component({
    selector: "app-filter-input-multiple-dialog",
    templateUrl: "./filter-input-multiple-dialog.component.html",
    styleUrls: ["./filter-input-multiple-dialog.component.scss"]
})
export class FilterInputMultipleDialogComponent implements OnInit {

    public formGroup: FormGroup;
    public filterResults: FilterResult[] = [];
    readonly addOnBlur = true;
    readonly separatorKeysCodes = [ENTER, COMMA] as const;

    constructor(@Inject(MAT_DIALOG_DATA) public data: FilterConfiguration,
                public dialogRef: MatDialogRef<any>,
                public formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.createFormGroup();
    }

    private createFormGroup(): void {
        this.formGroup = this.formBuilder.group({
            value: [null, CustomValidators.required]
        });
    }

    public remove(_filterResult: FilterResult): void {
        const index = this.filterResults.findIndex((filterResult: FilterResult) => filterResult.value === _filterResult.value);
        this.filterResults.splice(index, 1);
    }

    public add(): void {
        const value = this.formGroup.controls.value.value;
        const index = this.filterResults.findIndex((filterResult: FilterResult) => filterResult.value === value);
        if (index >= 0) {
            return;
        }

        const filterResult = new FilterResult();
        filterResult.value = value;

        this.filterResults.push(filterResult);

        this.formGroup.reset();
    }

    public ok(): void {
        const selectedFilter = new SelectedFilter();
        Object.assign(selectedFilter, this.data);
        selectedFilter.result = this.filterResults;
        this.dialogRef.close(selectedFilter);
    }

}
