import {Component, Input, OnInit} from "@angular/core";

export interface MenuItem {
    icon?: string;
    label: string;
    route?: string;
    divider?: boolean;
    hidden?: boolean;
    items?: MenuItem[];
}

@Component({
    selector: "app-menu",
    styleUrls: ["./menu.component.scss"],
    templateUrl: "./menu.component.html",
})
export class MenuComponent {

    @Input() data: MenuItem[];

    @Input() showLabel = true;

    constructor() {
    }
}
