import {Component, OnInit} from "@angular/core";
import {MainService} from "../main/main.service";
import {AuthService} from "../../../services/auth.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToastService} from "../../../services/toast.service";
import {TranslateService} from "../../../services/translate.service";
import {URLS} from "../../../app/app.urls";
import {DateAdapter} from "@angular/material/core";
import {MatButtonToggleChange} from "@angular/material/button-toggle";
import {take} from "rxjs/operators";
import {User} from "../../../models/account/user";


@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {

    public formGroup: FormGroup;

    constructor(public mainService: MainService,
                public toast: ToastService,
                public translate: TranslateService,
                public activatedRoute: ActivatedRoute,
                public router: Router,
                public fb: FormBuilder,
                public dateAdapter: DateAdapter<Date>,
                public authService: AuthService,) {
    }

    ngOnInit(): void {
        this.createFormGroup();
        this.afterLoginAzure();
    }

    private afterLoginAzure(): void {
        this.activatedRoute.queryParams.subscribe((params: Params): void => {
            const accessToken = params["access"];
            const refreshToken = params["refresh"];


            if (accessToken && refreshToken) {
                this.authService.setToken({access: accessToken, refresh: refreshToken});
                this.router.navigate([""]).then();
            }
        });
    }

    private createFormGroup(): void {
        this.formGroup = this.fb.group({
            username: ["", Validators.required],
            password: ["", Validators.required],
        });
    }

    public changeLanguage(event: MatButtonToggleChange): void {
        let language = event.source.value;

        if (language === "en") {
            this.translate.use(language);
            this.dateAdapter.setLocale(this.translate.currentLang);
        } else {
            this.translate.use("pt");
            this.dateAdapter.setLocale(this.translate.currentLang);
        }
    }


    public login(): void {
        const user: User = new User();
        user.username = this.formGroup.get("username").value;
        user.password = this.formGroup.get("password").value;
        this.authService.login(user)
            .pipe(take(1))
            .subscribe({
                    next: (_: Object): void => {
                        this.router.navigate(
                            this.authService.user.is_candidate
                                ? ["/rh/job/candidate_profile"] : ["/"]).then();
                    },
                    error: _ => {
                        this.formGroup.get("password").reset();
                    }
                }
            );
    }


    public loginAzure(): void {
        const urlBase = "https://api.bit.fpf.br";
        window.open(`${urlBase}${URLS.AZURE}` + "" + "?next=/home", "_self");
    }

}
