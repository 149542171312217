@if(for.matAutocomplete.isOpen) {
    <button mat-icon-button type="button"
            [matTooltip]="'previous-page' | translate"
            [matTooltipShowDelay]="1000"
            [disabled]="!for.paginatedResult.previous"
            (click)="previousPage($event)">
        <mat-icon>chevron_left</mat-icon>
    </button>
    <button mat-icon-button type="button"
            [matTooltip]="'next-page' | translate"
            [matTooltipShowDelay]="1000"
            [disabled]="!for.paginatedResult.next"
            (click)="nextPage($event)">
        <mat-icon>chevron_right</mat-icon>
    </button>
}
@if(canShowClearButton) {
    <button mat-icon-button type="button"
            [matTooltip]="'clear' | translate"
            [matTooltipShowDelay]="1000"
            (click)="clear($event)">
        <mat-icon>close</mat-icon>
    </button>
}
@if (canShowCreateButton) {
    <button mat-icon-button type="button"
            [matTooltip]="'save' | translate"
            [matTooltipShowDelay]="1000"
            (click)="create($event)">
        <mat-icon>save</mat-icon>
    </button>
} @else {
    <button mat-icon-button type="button"
            [matTooltip]="'open' | translate"
            [matTooltipShowDelay]="1000"
            [disabled]="for._disabled"
            (click)="openPopup($event)">
        <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
}
@if (for.matAutocomplete.isOpen) {
    <button mat-icon-button type="button"
            (click)="searchExact()">
        @if(_exact){
            <mat-icon [matTooltip]="'search-containing' | translate"
                      [matTooltipShowDelay]="1000">youtube_searched_for
            </mat-icon>
        } @else {
            <mat-icon [matTooltip]="'search-equal' | translate"
                      [matTooltipShowDelay]="1000"
                      class="rotated">pause_circle
            </mat-icon>
        }
    </button>
}
